import { createAction } from '@reduxjs/toolkit'
import {
  SET_ALL_TASKS,
  SET_TASK_SELECTED,
  SET_FILTER_SELECTED,
  SET_FORM_SELECTED,
  SHOULD_RELOAD_TASKS,
  SET_ARCHIVE_RECENTLY,
  ARCHIVE_TASKS,
  CREATE_NEW_TASK,
  SET_FORMS_FROM_API,
  SIGN_TASK,
  REMOVE_TASK,
  SET_TASKS_STATUS,
  SET_FORMS_LOADED,
  SET_FORMS_EMPTY,
} from "../constants/actions";
import { navigateForm } from "./menu";
import { getAllTasks, archiveTask, archiveOverdueTasks, saveTasks, getForms, signTask, removeTask, insertBase64, getForms2, getLogStatus, saveForm as _saveForm } from "../api";
import  storage  from "../services/storage"
import Swal from "sweetalert2";
import { getAllTaskFromLog } from "./records";

export function getTasks() {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASKS_STATUS)("loading"));
    getAllTasks(dispatch, getState).then(
      (response) => {        
        dispatch(createAction(SET_ALL_TASKS) (response)); 
        return response;
      });
    
  }
}

export function archiveTaskAction(task) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASKS_STATUS)("loading"));
    archiveTask(dispatch, getState, task).then(
      (response) => {      
          
        dispatch(createAction(SET_ALL_TASKS) (response)); 
        dispatch(createAction(SHOULD_RELOAD_TASKS)(true));
        return response;
      });
    //dispatch(createAction(SET_ALL_TASKS)(archiveTask(dispatch, getState, task)));
    
  };
}

export function archiveOverdueTasksAction(task) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASKS_STATUS)("loading"));
    archiveOverdueTasks(dispatch, getState, task).then(
      (response) => {      
          
        dispatch(createAction(SET_ALL_TASKS) (response)); 
        dispatch(createAction(SHOULD_RELOAD_TASKS)(true));
        return response;
      });
    //dispatch(createAction(SET_ALL_TASKS)(archiveTask(dispatch, getState, task)));
    
  };
}



export function setAllTasks(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_ALL_TASKS)(data));
    dispatch(createAction(SHOULD_RELOAD_TASKS)(false));
  };
}

export function setTaskSelected(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_TASK_SELECTED)(data));
  };
}
export function setFilterSelected(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_FILTER_SELECTED)(data));
  };
}
export function setFormSelected(data) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_FORM_SELECTED)(data));
  };
}
export function shouldReloadTasks(value) {
  return (dispatch, getState) => {
    dispatch(createAction(SHOULD_RELOAD_TASKS)(value));
  };
}

export function createNewTask(task) {
  return (dispatch, getState) => {
    dispatch(createAction(SHOULD_RELOAD_TASKS)(true));
    const promise = saveTasks(dispatch, getState, task)
        .then((response) => {
          dispatch(createAction(CREATE_NEW_TASK)(response));
          storage.set("NewTask", response);
          //dispatch(createAction(SET_ALL_TASKS)(getAllTasks(dispatch, getState)));
          getAllTasks(dispatch, getState).then(
            (response) => {        
              dispatch(createAction(SET_ALL_TASKS) (response)); 
              return response;
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(createAction(INVALID_REGISTER_HIDE)(false));
          Swal.fire({
            text: "Error ",error,
            confirmButtonText: "Close",
            confirmButtonColor: "#F4981E",
            backdrop: false
          });
          throw new Error("Register error");
        });
  };
}
export function setFormsFromAPI(task) {
  return (dispatch, getState) => {

    // getForms(dispatch, getState).then(
    //   (response) => {        
    //     dispatch(createAction(SET_FORMS_FROM_API) (response)); 
    //     return response;
    //   });
    dispatch(createAction(SET_FORMS_LOADED)(false));
    dispatch(createAction(SET_FORMS_EMPTY)());
    getForms2(dispatch, getState);

    //dispatch(createAction(SET_FORMS_FROM_API)(getForms(dispatch, getState)));
  };
}
export function signTaskAction(signature) {
  return (dispatch, getState) => {
    getLogStatus(dispatch, getState,signature.task_id).then((res)=>{
      var status = res?.status ? res?.status[0] ? res?.status[0].status: "" : "";

      console.log(status)
      if(!status || status == "pending" || status == "edit_required"){
        insertBase64(dispatch, getState, signature.signature);
        signTask(dispatch, getState, signature).then((response) => {
          if(response.msg){
            Swal.fire({
              text: response.msg,
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              showCancelButton: true,
              confirmButtonColor: "#F4981E",
              backdrop: false
            
            })
          } else{
            dispatch(createAction(SIGN_TASK)(response));
            dispatch(getAllTaskFromLog({ page:1 }));
            return;
          }  
        })
      }else{
        dispatch(getTasks());
        if(status)status = status.replace("_"," ");
        Swal.fire({
          text: "This log is actually " + status + " in other device.",
          confirmButtonText: "OK",
          confirmButtonColor: "#F4981E",
          backdrop: false
        });
      }

      return;
    })
    
  };
}


export function removeTaskAction(task) { console.log("REQUST=>" + JSON.stringify(task));
  return (dispatch, getState) => {
      removeTask(dispatch, getState, task).then((response) => {  
        dispatch(createAction(REMOVE_TASK)( response));       
          return response;
      })
    
  };
}
export function saveFormFormAIPrompt(form, callback) {
	return (dispatch, getState) => {
		if (form) {
			const promise = _saveForm(dispatch, getState, {
				form: form,
				save_as_template: false,
			});

			promise.then(resp => {
				if(callback)callback();
			})
      .catch(error => {
        Swal.fire({
          text: "Sorry, there was an error creating your new form, please try again.",
          confirmButtonText: "OK",
          confirmButtonColor: colors.orange,
          backdrop: false
        });
      });
		}
	};
}
