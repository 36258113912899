import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import { 
		 MENU_REQUEST,
         MENU_RESPONSE,
         FORM_REQUEST,
         FORM_RESPONSE,
         SUBMIT_REQUEST,
         SUBMIT_RESPONSE,
         NAVIGATE_MENU,
         SUBMIT_BEGIN,
         INFO_DIALOG_OPEN,
         INFO_DIALOG_CLOSE,
         IMAGE_REQUEST,
         IMAGE_REQUEST_FOUND,
         IMAGE_RESPONSE,
         SUBMISSION_ADD,
         SUBMISSION_FORM_SUCCESS,
         BIG_FILE_LOADING
} from '../constants/actions';
//import { Actions, ActionConst } from 'react-native-router-flux';
import { Alert } from 'react-native';
import StorageService from '../services/storage';

import { getApiPath } from '../api';
import Swal from "sweetalert2";

const defaultState = Immutable.fromJS({
  flag: true,
  menu: {
  	items: [],
  	//ship: '',
  	//showShipName: false,
  	status: 'loading',
  },
  form: {
  	name: '',
  	status: 'loading',
  	errorMessage: 'An error occured while retrieving the form.',
  	components: [],
  	choicelist: [],
  	submission: [],
    promptMessages: null,
    id: null,
  },
  currentView: 'login',
  imagesRetrieved: false,
});

export default handleActions({
	[MENU_REQUEST]: state => {
		return state.setIn(['menu', 'status'], 'loading');
	},
	[MENU_RESPONSE]: {
		next(state, { payload: { response }}) {
      let menu = response.forms;
      //const ship = response.ship;

      menu.forEach(m => {
        m.imageBlob = {uri: getApiPath() + m.image};
      });
      StorageService.setMenu(menu);

			return state.setIn(['menu','items'], menu)
			.setIn(['menu', 'status'], 'loaded');
			//.setIn(['menu', 'ship'], ship);
		},
		throw(state, action) {
      const menu = StorageService.getMenu();
      if (menu)
        return state.setIn(['menu','items'], menu)
        .setIn(['menu', 'status'], 'loaded');
        //.setIn(['menu', 'ship'], StorageService.getShip());

      else
    		return state
      	.setIn(['menu', 'status'], 'error');
      	//.setIn(['menu', 'ship'], '');
    	},
  },  
  [SUBMISSION_FORM_SUCCESS]: (state, action) => {    
		return state.setIn(['form','status'],'loaded');
	},
	[FORM_REQUEST]: (state, action) => {
    const id = action.payload.id;
		return state.set('currentView','form')
      .setIn(['form','id'], id)
			.setIn(['form','status'],'loading');
	},
  [BIG_FILE_LOADING]: (state, action) => {    
    console.log("setting the action",action.payload)
    return state.setIn(['form','status'],action.payload);
  }, 
	[FORM_RESPONSE]: {
    next(state, action) {

      let response=action.payload;
      const components = response.components;
      const choicelist = response.choicelist;
      const name = response.name;
      const formSubmission = response.formSubmission;
      const id = state.getIn(['form', 'id']);
      const promptMessages = response.promptMessages ? response.promptMessages : null;
      const isRecipe = response.isRecipe ? response.isRecipe : false;
      const defaultYield = response.defaultYield ? response.defaultYield : null;

      StorageService.setForm(id, { components, choicelist });

		  return state.setIn(['form','components'], components)
  			.setIn(['form', 'choicelist'], choicelist)
        .setIn(['form', 'formSubmission'], formSubmission)
        .setIn(['form', 'name'], name)
  			.setIn(['form', 'submission'], '')
  			.setIn(['form','status'], 'loaded')
        .setIn(['form','promptMessages'], promptMessages)
        .setIn(['form','isRecipe'], isRecipe)
        .setIn(['form','defaultYield'], defaultYield);
		},
		throw(state, action) {

      const id = state.getIn(['form', 'id']);
      const form = StorageService.getForm(id);
      if (form)
        return state.setIn(['form','components'], form.components)
        .setIn(['form', 'choicelist'], form.choicelist)
        .setIn(['form', 'submission'], '')
        .setIn(['form','status'], 'loaded');

      else
    		return state
      	.setIn(['form', 'status'], 'errorRetrieving')
      	.setIn(['form', 'errorMessage'], 'An error occured while retrieving the form.');
    	},
	},

  [SUBMISSION_ADD]: (state, action) => {
    return state.setIn(['form', 'status'], 'saving');
  },

	[SUBMIT_REQUEST]: state => {
		return state.setIn(['form', 'status'], 'saving');
	},
	[SUBMIT_RESPONSE]: {
		next(state, { payload: { response }}) {
			Swal.fire({
        title: "Success!",
        text: 'The form has been submitted successfully.',
        confirmButtonText: "OK",
        confirmButtonColor: "#F4981E",
        backdrop: false

      });
			return state;
		},
		throw(state, action) {
      		return state
        	.setIn(['form', 'status'], 'errorSaving')
        	.setIn(['form', 'errorMessage'], 'An error occured while saving the form.');
    	},
	},
	//ActionConst.FOCUS
	//ActionConst.PUSH
	/*[ActionConst.BACK_ACTION]: (state, action) => {
		return state.set('currentView','menu');
	},*/
	[NAVIGATE_MENU]: state => {
		// Reset form view to 'loading' state for next form navigation
		return state.setIn(['form','status'],'loading')
      .set('currentView','menu');
	},

	[INFO_DIALOG_OPEN]: {
    next(state, action) {
    	console.log('INFO_DIALOG_OPEN!');
      return state
      .set('dialogOpen', true);
    },
    throw(state, action) {
      return state;
    }
  },
  [INFO_DIALOG_CLOSE]: {
    next(state, action) {
      return state
      .set('dialogOpen', false);
    },
    throw(state, action) {
      return state;
    }
  },
  [IMAGE_REQUEST]: (state, action) => {
    return state.set('imagesRetrieved', true);
  },
  [IMAGE_REQUEST_FOUND]: (state, action) => {
    const { id, url } = action.payload;
    let menu = state.getIn(['menu','items']);
    const image = StorageService.getImage(url);

    menu.forEach(m => {
        if (m.id == id) {
          m.imageBlob = {uri: image};
        }
      });
    return state.setIn(['menu', 'items'], menu.slice());
  },
  [IMAGE_RESPONSE]: {
    next(state, action) {
      const { id, response, url } = action.payload;
      let menu = state.getIn(['menu','items']);

      menu.forEach(m => {
        if (m.id == id) {
          m.imageBlob = {uri: response};
        }
      });
      StorageService.setImage(url, response);
      return state.setIn(['menu', 'items'], menu.slice());
    },
    throw(state, action) {
      return state;
    }
  },


}, defaultState);