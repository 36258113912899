import React, { Component } from "react";
import { View, ScrollView, Text, StyleSheet } from "react-native";
import FormView from './form';
import { getSalesGuide } from "../actions/login";
import { connect } from "react-redux";
import colors from "../constants/colors";
import LogoTitle from '../components/widgets/companyLogo'
class SalesGuide extends Component{
    
    constructor(props) {
        super(props);
        
    }
    componentDidMount(){
    }

    render(){
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var form = urlParams.get('id');
        console.log('form ' + form)
        return (
          <>
          
            <View style={styles.titlebar}>
              <LogoTitle />
            </View> 
            <FormView 
                formId={form}
                form_id={form}
                title={""} 
                isSubmission={false} 
                newReport={true} 
                task_id={""} 
                log_group_id={""} />
          </>)
        
    }
}
export default connect(
    (state) => {
      return {  
        loginLoading: state.user.get("loginLoading"), 
        userToken: state.user.get('authToken'),   
      };
    },
    (dispatch, props) => {
      return {
        getSalesGuide: (id) => {
          dispatch(getSalesGuide(id));
        },
        dispatch,
      };
    }
  )(SalesGuide);

  const styles = StyleSheet.create({
    titlebar:{ 
      height: 90,
      width: '100%',
      textAlign: 'center',
      paddingTop: 10,
      backgroundColor: colors.primary
    }
  });
