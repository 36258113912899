import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  TextInput,
  FlatList,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
  TouchableHighlight,
  Dimensions,
  Alert,
  Platform,
  ActivityIndicator
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { connect } from "react-redux";
import colors from "../constants/colors";
import Icon from "react-native-vector-icons/MaterialIcons";
import TaskCard from "./widgets/taskCard";
import moment from "moment-timezone";
import { navigateForm } from "../actions/menu";
import axios from "axios";
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import { navigateRecord } from "../actions/menu";
import {
  getTasks,
  setTaskSelected,
  shouldReloadTasks,
  archiveTaskAction,
  archiveOverdueTasksAction
} from "../actions/tasks";
import { getLogGroupsTasks, getLogGroupsAction, setLogGroupSelected, setIsLogGroup, getGroupDetail } from "../actions/loggroups";
import Animations from "./animations"
import alerts from "./components/alert"
import { color } from "react-native-reanimated";
import PaymentInfo from './dialogs/paymentInfo';
import Swal from "sweetalert2";
// import "./styles.css";
import { viewContainer } from "./functions";


let _interval = null;



class MainView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataFiltered: [],
      search: "",
      width: window.innerWidth,
    };
  }

  filterTasks = (search) => {
    /*let filter = [];*/
    let filter = this.props?.tasks;
    filter = this.props.tasks.filter((item) => {
      return (
        item.forms_id ?
          item.name.toLowerCase().includes(search.toLowerCase()) &&
          (this.getTimeConverted(item.start_date) ==
            moment().format("YYYY-MM-DD") ||
            this.isBeforeToday(item.start_date)) &&
          (item.status ? item.status.toLowerCase() : "Unknown") != "archive" && /*(item.status  != "archived" || item.status  != "Archived")*/
          (item?.status != "archived") &&
          (item?.submission?.status != "archived") :
          item.name.toLowerCase().includes(search.toLowerCase()) &&
          (this.getTimeConverted(item.start_date) ==
            moment().format("YYYY-MM-DD") ||
            this.isBeforeToday(item.start_date)) &&
          (item.status ? item.status.toLowerCase() : "Unknown") != "archive" && /*(item.status  != "archived" || item.status  != "Archived")*/
          (item?.status != "archived")


      );
    });

    filter = filter.sort(this.compare_date);
    // It was required to have on top of list a task with edit required status, so we re sort the list
    filter.sort((a, _) => {
      if (a?.submission?.status == "edit_required")
        return -1;
      if (a?.submission?.status != "edit_required")
        return 1;
      return 0;
    });
    this.setState({
      dataFiltered: filter,
    });
  };

  isBeforeToday = (date) => {
    let d = moment.tz(date, "UTC");
    return moment.tz(d, moment.tz.guess(true)).isBefore(moment(new Date()));
  };

  compare_date = (a, b) => {
    if (a.expiration_date < b.expiration_date) {
      return -1;
    } else if (a.expiration_date > b.expiration_date) {
      return 1;
    } else {
      return 0;
    }
  };

  archiveTask = (task) => {
    let object = {
      task_id: task._id,
      name: task.name,
      description: task.description,
      forms_id: task.forms_id,
      start_date: moment().format("YYYY-MM-DD HH:mm"),
      expiration_date: moment().add(1, "day").format("YYYY-MM-DD HH:mm"),
      repeat_frequency: "",
      status: "archive",
    };
    this.props.archiveTask(object);
  };

  archiveOverdueTasks = ({ expiration_date, start_date, name, forms_id = false, log_group_id, status, submission }) => {
    const { companyTimezone } = this.props;
    var start = moment.utc(start_date).tz(companyTimezone);
    var expiration = moment.utc(expiration_date).tz(companyTimezone);

    if ((status !== "complete" && submission && typeof log_group_id !== undefined)
      || (status !== "complete" && typeof log_group_id === undefined)) {
      Swal.fire({
        title: "Overdue tasks",
        text: "Some tasks cannot be deleted because they might contain important information. We will delete the empty entries and leave other forms for you to review and choose if you want to delete them. " + "Name: " + name + " Start date: " + start.format("YYYY-MM-DD HH:mm") + " Expiration date: " + expiration.format("YYYY-MM-DD HH:mm"),
        confirmButtonText: "Accept",
        confirmButtonColor: "#F4981E",
        backdrop: false

      });
    }
    this.props.archiveOverdueTasks({ expiration_date, ...(forms_id && { forms_id }), ...(log_group_id && { log_group_id }) });
  };
  
  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  componentDidMount() {
    this.props.getTasks();
    //_interval = setInterval(() => this.props.getTasks(), 300000);
    //console.log("PROPS =>"+JSON.stringify(this.props))
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.reload !== this.props.reload) {
      this.props.getTasks();
    }

    if (prevProps.tasks !== this.props.tasks) {
      //   let arr = this.props.tasks;
      //   let log = this.props.logGroups.map((item) => {
      //     item.type = "group";
      //     return item;
      //   });
      //   let newArray = arr.concat(log);
      //   this.setState(
      //     {
      //       data: newArray,
      //     },
      //     () => this.filterTasks("")
      //   );
      // }

      if (!this.props.tasks) {
        this.props.getTasks().then(() => {
          this.filterTasks("");
        });
      } else {
        // console.log(JSON.stringify(this.props.tasks))
        this.filterTasks("");
      }

    }
  }

  componentWillUnmount() {
    clearInterval(_interval);
    window.removeEventListener('resize', this.handleResize);
  }


  goToDetailTask = (item) => {

    clearInterval(_interval);
    if (typeof item.log_group_id !== "undefined" && item.log_group_id != "" && item.log_group_id != null) {
      this.props.setTaskSelected({ ...item });
      this.props.getGroupDetail(item.log_group_id);
      this.props.setIsSubmission(false);
      this.props.setIsLogGroup(true);
      this.props.navigation.navigate('GroupView');
    } else {
      this.props.setTaskSelected({ ...item });
      this.props.setIsLogGroup(false);
      if (!item.submission) {
        this.props.selectForm(
          item.forms_id,
          item.name,
          this.props.previousSubmissions,
          item._id,
          item?.isOrder,
          item?.submission?.isRecipe,
          item?.defaultYield,
          item?.isInvoice,
          item?.isShipmentLog,
          item?.isInventoryTransfer
        );
      } else {
        this.props.setSubmissionSelected({ ...item.submission });
        this.props.setIsSubmission(true);
        this.props.setIsLogGroup(false);
        this.props.navigation.navigate('FormView', { formId: item.submission.id, title: item.submission.name, newReport: false, submission: item.submission, task_id: null, log_group_id: null, isPallet: item?.submission?.isPallet, isReceivingLog: item?.submission?.isReceivingLog, isOrder: item?.isOrder, isRecipe: item?.submission?.isRecipe, defaultYield: item?.submission?.defaultYield, isInvoice: item?.submission?.isInvoice, isShipmentLog: item?.submission?.isShipmentLog , isReconciliationLog: item?.submission?.isReconciliationLog, isInventoryTransfer: item?.submission?.isInventoryTransfer });

        /*this.props.navigateRecord(
          item.submission.id,
          item.submission.name,
          false
        );*/
      }

    }
    // Actions.taskdetail();
  };

  goToFilter = () => {
    //Actions.taskFilter();
  };

  getChipColor = (frequency) => {
    let chipColor = "primary";
    switch (frequency) {
      case "Day":
        chipColor = "primary";
        break;
      case "Week":
        chipColor = "accent";
        break;
      case "Month":
        chipColor = "cyan";
        break;
      case "Annual":
        chipColor = "ambar";
        break;
      default:
        chipColor = "primary";
    }
    return chipColor;
  };

  getExpireDate = (date) => {
    let d = moment.tz(date, "UTC");
    var date_future = moment(
      moment.tz(d, moment.tz.guess(true)),
      "YYYY-MM-DD HH:mm:ss"
    );
    var date_now = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
    let dif = date_future.diff(date_now, "seconds");

    var minutes = Math.floor(dif / 60);

    var hours = Math.floor(minutes / 60);

    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;

    if (dif < 0) {
      return "The task expired on " + this.getTimeConverted(date);
    }
    return days + " days, " + hours + " hours and " + minutes + " minutes.";
  };

  isTaskOverdue = (date) => {
    let d = moment.tz(date, "UTC");
    var date_future = moment(
      moment.tz(d, moment.tz.guess(true)),
      "YYYY-MM-DD HH:mm:ss"
    );
    var date_now = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
    let dif = date_future.diff(date_now, "seconds");
    var minutes = Math.floor(dif / 60);

    var hours = Math.floor(minutes / 60);

    if (dif < 0) {
      return [true, hours];
    }
    return [false, hours];
  };

  getTimeConverted(date) {
    let d = moment.tz(date, "UTC");
    return moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD");
  }

  getTime(date) {
    let d = moment.tz(date, "UTC");
    if (moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
      return "Today at " + moment.tz(d, moment.tz.guess(true)).format("h:mm A");
    else
      return moment.tz(d, moment.tz.guess(true)).format("MM/DD/YYYY");
  }

  refreshTasks() {
    if (this.props.tasksStatus == "idle") {
      this.props.getTasks();
      this.props.setTaskSelected({});
    }

  }

  openRecords() {
    const { tasksStatus, isSaving } = this.props;
    const recordSaving = isSaving.get('status') == 'saving' || tasksStatus == 'loading';
    if (!recordSaving)
      this.props.navigation.navigate('RecordsView');
  }

  render() {
    const { tasksStatus, isSaving } = this.props;
    const recordSaving = isSaving.get('status') == 'saving' || tasksStatus == 'loading';
    const visible = this.props.payment_completed ? false : true;
    return (
      <View style={{
        flexDirection: "row",
      }}>
        <PaymentInfo visible={visible} />
        <View style={{ flex: 1, }} />
        <View className={"viewContainer"} style={viewContainer(this.state.width, styles)}>
          <View >
            <ScrollView>
              <View style={styles.commandsContainer}>
                <TouchableOpacity
                  disabled={recordSaving}
                  style={styles.newRecordCommand}
                  activeOpacity={.5}
                  onPress={() => {
                    this.props.setLogGroupSelected({});
                    this.props.setIsLogGroup(false);
                    //Actions.newTask();
                    this.props.navigation.navigate('NewTask');
                  }}>
                  <Text style={styles.commandText}>New Reports</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  disabled={recordSaving}
                  style={styles.newLodBtn}
                  activeOpacity={.5}
                  onPress={() => {
                    this.props.navigation.navigate('NewLogTask');
                  }}>
                  <Text style={styles.commandText}>New Logs</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  disabled={recordSaving}
                  style={styles.recordsCommand}
                  activeOpacity={.5}
                  onPress={() => {
                    this.props.setLogGroupSelected({});
                    this.props.setIsLogGroup(false);
                    this.openRecords();
                  }}>
                  <Text style={styles.commandText}>Review Reports</Text>
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Text style={styles.title2}>Here are your current:</Text>
                <Text style={styles.taskNumber}>{this.state.dataFiltered.length} {this.state.dataFiltered.length === 1 ? "Task" : "Tasks"}</Text>
              </View>
              <TouchableOpacity disabled={recordSaving} onPress={() => { this.refreshTasks() }} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: 30, }}>
                <Text style={styles.lastUpdated}>Last Updated: {this.props.lastUpdated ? this.getTime(this.props.lastUpdated) : null}</Text>
                {this.props.tasksStatus == "idle"
                  ?
                  <MaterialCommunityIcons
                    name="refresh"
                    style={styles.refreshIcon}
                  /> : <Animations animationfile='loading-dots-gray' />}


              </TouchableOpacity>

              <View>
                <View style={{
                  height: 40,
                  borderRadius: 20,
                  flexDirection: "row",
                  backgroundColor: colors.gray_darken_2,
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}>
                  <Icon
                    style={styles.searchIcon}
                    name="search"
                    size={30}
                    color="#90A4AE"
                  />
                  <TextInput
                    style={{
                      borderRadius: 20,
                      flex: 1,
                      maxWidth: 470,
                      height: 30,
                      paddingTop: 10,
                      paddingRight: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      fontSize: 18,
                      color: "#90A4AE",
                    }}
                    placeholder="Search for a task"
                    onChangeText={(searchString) => this.filterTasks(searchString)}
                    underlineColorAndroid="transparent"
                  />
                </View>
              </View>

              <View style={{ backgroundColor: colors.gray_darken_2, padding: 10, borderRadius: 20 }}>
                {
                  this.state.dataFiltered.length > 0 ? (
                    this.props.tasksStatus == "idle" ?
                      (<FlatList
                        data={this.state.dataFiltered}
                        renderItem={({ item }) => (
                          <TaskCard
                            style={{ wordBreak: 'break-all', }}
                            _id={item._id}
                            taskStatus={item.status}
                            submission={item.submission}
                            taskName={item.name}
                            isLogGroup={(typeof item.log_group_id !== "undefined" && item.log_group_id != "" && item.log_group_id != null) ? true : false}
                            date={this.getTimeConverted(item.start_date)}
                            timeLeft={this.getExpireDate(item.expiration_date)}
                            frequency={item.repeat_frequency}
                            chipType={this.getChipColor(item.repeat_frequency)}
                            hasChipOutline={
                              this.isTaskOverdue(item.expiration_date)[0]
                                ? this.isTaskOverdue(item.expiration_date)[0]
                                : this.isTaskOverdue(item.expiration_date)[1] < 24
                                  ? true
                                  : this.isTaskOverdue(item.expiration_date)[0]
                            }
                            outlineText={
                              this.isTaskOverdue(item.expiration_date)[0]
                                ? "Overdue"
                                : this.isTaskOverdue(item.expiration_date)[1] < 24
                                  ? "Due today"
                                  : ""
                            }
                            chipTypeOutlined={
                              this.isTaskOverdue(item.expiration_date)[0]
                                ? "error"
                                : this.isTaskOverdue(item.expiration_date)[1] < 24
                                  ? "dueToday"
                                  : ""
                            }
                            action={() => this.goToDetailTask(item)}
                            archiveAction={() =>
                              Swal.fire({
                                title: "Clearing task",
                                text: "This action will remove the task for your team, please confirm.",
                                confirmButtonText: "Clear task",
                                cancelButtonText: "Cancel",
                                showCancelButton: true,
                                confirmButtonColor: "#F4981E",
                                backdrop: false
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  this.archiveTask(item);
                                }
                              })
                            }
                            confirmOtherOverdues={this.isTaskOverdue(item.expiration_date)[0]}
                            archiveOverduesAction={() =>
                              Swal.fire({
                                title: "Clearing all overdue tasks",
                                text: "Are you sure you want to delete all overdue tasks from all accounts in your company?",
                                confirmButtonText: "Clear task",
                                cancelButtonText: "Cancel",
                                showCancelButton: true,
                                confirmButtonColor: "#F4981E",
                                backdrop: false
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  this.archiveOverdueTasks(item);
                                }
                              })
                            }
                          />

                        )}
                        keyExtractor={(item, index) => index.toString()}
                      />) : (
                        <View
                          style={{
                            flex: 1,
                            backgroundColor: "#FFF",
                            padding: 40,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ActivityIndicator size="large" color={colors.primary} />
                        </View>
                      )
                  ) : (
                    <View style={styles.searchSection}>
                      <Text
                        style={{ fontSize: 14, color: colors.blue_gray, paddingLeft: 15 }}
                      >
                        There are currently no tasks associated with your role
                      </Text>
                    </View>
                  )
                }
              </View>
            </ScrollView>
          </View>
        </View>
        <View style={{ flex: 1 }}>

        </View>
      </View>
    )
  }
}

const mapStateToProps = state => {
  return {
    lastUpdated: state.tasks.get("lastUpdated"),
    tasks: state.tasks.get("tasks"),
    filter: state.tasks.get("filter"),
    taskSelected: state.tasks.get("taskSelected"),
    reload: state.tasks.get("reload"),
    archiveRecently: state.tasks.get("archiveRecently"),
    previousSubmissions: state.submissions.get("previousSubmissions"),
    token: state.user.get("authToken"),
    logGroups: state.loggroups.get("logGroups"),
    tasksStatus: state.tasks.get("status"),
    isSaving: state.forms.getIn(['form'], 'status'),
    payment_completed: state.user.get("payment_completed"),
    companyTimezone: state.user.get("companyTimezone"),


  };
};
export default connect(
  mapStateToProps,
  (dispatch, props) => {
    return {
      mainview: (user_data) => {
        dispatch(mainview(user_data));
      },
      getTasks: () => {
        dispatch(getTasks());
        dispatch(getLogGroupsTasks());
        dispatch(getLogGroupsAction());

      },
      setTaskSelected: (value) => {
        dispatch(setTaskSelected(value));
      },
      shouldReloadTasks: (value) => {
        dispatch(shouldReloadTasks(value));
      },
      archiveTask: (task) => {
        dispatch(archiveTaskAction(task));
      },
      archiveOverdueTasks: (task) => {
        dispatch(archiveOverdueTasksAction(task));
      },
      selectForm: (id, name, previousSubmissions, task_id, isOrder, isRecipe, defaultYield, isInvoice, isShipmentLog, isInventoryTransfer) => {
        dispatch(setIsSubmission(false));
        dispatch(setSubmissionSelected(null));
        props.navigation.navigate('FormView', { formId: id, title: name, newReport: true, submission: null, task_id: task_id, log_group_id: null, isOrder: isOrder, isRecipe: isRecipe, defaultYield: defaultYield, isInvoice: isInvoice, isShipmentLog: isShipmentLog, isInventoryTransfer: isInventoryTransfer });
        //dispatch(navigateForm(id, name, true, task_id));                
      },
      setLogGroupSelected: (data) => {
        dispatch(setLogGroupSelected(data));
      },
      setIsLogGroup: (data) => {
        dispatch(setIsLogGroup(data));
      },
      getGroupDetail: (id) => {
        dispatch(getGroupDetail(id));
      },
      setSubmissionSelected: (value) => {
        dispatch(setSubmissionSelected(value));
      },
      setIsSubmission: (value) => {
        dispatch(setIsSubmission(value));
      },
      navigateRecord: (id, name, newReport) => {
        dispatch(navigateRecord(id, name, newReport));
      },
    };
  }
)(MainView);


const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  commandsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
    marginHorizontal: 15,
  },
  newRecordCommand: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    borderRadius: 30,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 0,
    backgroundColor: colors.gray_darken_2,
    marginRight: 1,
  },
  newLodBtn: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 0,
    backgroundColor: colors.gray_darken_2,
  },
  commandText: {
    fontSize: 15,
    fontWeight: "700",
    marginLeft: 8,
    color: colors.primary,
  },
  recordsCommand: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    borderWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 50,
    borderWidth: 0,
    backgroundColor: colors.gray_darken_2,
    marginLeft: 1,
  },
  taskNumber: {
    fontFamily: "Roboto-Bold",
    fontSize: 20,
    paddingLeft: 20,
    paddingVertical: 10,
    color: colors.primary,
  },
  lastUpdated: {
    fontFamily: "Roboto-Bold",
    fontSize: 12,
    color: colors.subtitle,
  },
  refreshIcon: {
    fontSize: 15,
    paddingHorizontal: 10,
    color: "gray",
  },
  searchSection: {
    height: 60,
    flexDirection: "row",
    backgroundColor: "#fff",
    alignItems: "center",
    marginTop: 10,
  },
  searchIcon: {
    paddingLeft: 20,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },

  buttonText: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: 14,
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 6,
    flex: 1,
    marginVertical: 20,
    padding: 30,
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    minHeight: 600,
  },
  loadingAnimation: {
    height: 20,
    width: 20,
    position: "absolute",
    right: 5,
  },
  container: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  containerInside: {
    alignItems: "center",
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  topBar: {
    paddingVertical: 20,
    backgroundColor: colors.primary,
    justifyContent: "center",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.2,
    shadowColor: "black",
    zIndex: 10,
  },
  title: {
    color: "#fff",
    fontSize: 30,
    textAlign: "center",
    fontWeight: "600",
  },
  title2: {
    fontFamily: "Roboto-Bold",
    fontSize: 20,
    paddingLeft: 10,
    paddingVertical: 10,
  },
  subtitle: {
    fontSize: 22,
    fontWeight: "600",
    paddingTop: 10,
    paddingBottom: 10,
    color: colors.primary,
  },

  shipName: {
    paddingTop: 10,
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
    fontWeight: "400",
  },
  wordTwo: {
    fontWeight: "300",
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  input: {
    flex: 1,
    height: 60,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontSize: 18,
    backgroundColor: "#fff",
    color: "#90A4AE",
  },
  button: {
    flex: 1,
    backgroundColor: colors.userCommand,
    padding: 0,
    height: 50,
    borderRadius: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    marginLeft: 15,
    borderRadius: 5,
  },
  buttonCancel: {
    flex: 1,
    backgroundColor: "#ccc",
    padding: 0,
    height: 50,
    borderRadius: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    marginRight: 15,
    borderRadius: 5,
  },
  text: {
    fontSize: 16,
    fontWeight: "normal",
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "left",
    color: "#888",
  },
  viewContainer: { 
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10
  },
  viewContainerMax: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
});
