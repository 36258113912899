import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet, Platform, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import colors from "../constants/colors";
import ChatAI from './components/chatAI';
import { setIsLogGroup, setLogGroupSelected } from '../actions/loggroups';
import uuid from "uuid";
import { requestForm, sendForm } from '../api';
import { useFocusEffect } from '@react-navigation/native';
import AppJson from '../../app.json';
// import FloatingMenu from './widgets/floatingMenu';
import RecordActions from './widgets/recordActions';

const requestFormWrapper = (id, callback) => {
    return (dispatch, getState) => {
        requestForm(dispatch, getState, id).then((response) => {
            callback(response);
        });
    };
}

const sendFormWrapper = (sendData, id, callback) => {
    return (dispatch, getState) => {
        sendForm(dispatch, getState, sendData, id).then((res) => {
            if (callback) callback(res);
        });
    }
}

const PromptFormView = ({ route, promptMessages, completed, form, formLoaded, formName, submitForm, submitFormSave, submissionSelected }) => {
    //   const { promptMessages, form } = { ...route.params };
    //   const completed = route.params?.completed || false;
    const dispatch = useDispatch();
    const tasksStatus = useSelector(state => state.tasks.get("status"));
    const isSaving = useSelector(state => state.forms.getIn(['form'], 'status'));
    const recordSaving = isSaving.get('status') == 'saving' || tasksStatus == 'loading';
    const [submission, setSubmission] = useState({});
    const [completeForm, setCompleteForm] = useState(null);
    const [componentId, setComponentId] = useState(0);
    const [placeholder, setPlaceholder] = useState(0);
    const [initialResponse, setInitialResponse] = useState(null);
    const [clearChat, setclearChat] = useState(false);
    const [csvData, setCsvData] = useState(null);
    const [changeMenu, setChangeMenu] = useState(false);


    const deviceWidth = Dimensions.get('screen').width > 1000;
    const openRecords = () => {
        if (!recordSaving) navigation.navigate('RecordsView');
    }

    useEffect(() => {
        dispatch(requestFormWrapper(form?._id, (response) => {
            const id = response.components.find((c) => c.type == 'chatAI')?.id || 0;
            const placeholder = response.components.find((c) => c.type == 'chatAI')?.placeholder || 0;
            setCompleteForm(response);
            setComponentId(id);
            setPlaceholder(placeholder);
        }));
        if (Object.keys(submission).length > 0) {
            handleSaveForm((res) => {
                setInitialResponse({});
                setSubmission({});
                setclearChat(!clearChat);
            });
        }
    }, [form]);

    const willUnmount = () => {
        if (Object.keys(submission).length > 0 && !changeMenu) {
            handleSaveForm((res) => {
                setInitialResponse({});
                setclearChat(!clearChat);
            });
        }
    }
    const handleOnChange = (_, sub, __) => {
        let flatConversation = '';
        sub.forEach(chat => {
            if (chat?.table) setCsvData(chat.table);
            flatConversation += `Q: ${chat.question}.A: ${chat.answer}.\n`;
        });
        setSubmission({ [componentId]: { response: flatConversation, category: promptMessages.category } });
    }

    const handleSaveForm = (callback) => {
        if (!completeForm) return;
        if (csvData) {
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'assistantReport.csv';
            a.click();
            URL.revokeObjectURL(url);
        }
        dispatch(sendFormWrapper({
            components: completeForm.components,
            choicelist: completeForm.choicelist,
            submission,
            logroup: "",
            uuid: uuid.v1(),
            client: AppJson.expo.version,
            task_id: "",
            status: "archived",
            isPallet: false,

        }, completeForm._id, callback));
    }

    return (
        <>
            {/* <FloatingMenu /> */}
            <View style={deviceWidth ? styles.viewContainerMax1 : styles.viewContainerMax2}>
                <ScrollView>
                    <View>
                        {
                            formName &&
                            <View style={{
                                display: 'flex', flexDirection: 'row', marginTop: 20
                            }}>
                                <Text style={styles.labelFirst3}>{formName}</Text>
                            </View>
                        }
                        <ChatAI promptMessages={promptMessages} initial={initialResponse} onChange={handleOnChange} id={componentId} isCompleted={completed} fromPrompt={true} placeholder={placeholder} clearChat={clearChat} willUnmount={willUnmount} />
                        <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }}
                            formLoaded={formLoaded}
                            submitForm={(status) => dispatch(sendFormWrapper({
                                components: completeForm.components,
                                choicelist: completeForm.choicelist,
                                submission,
                                logroup: "",
                                uuid: uuid.v1(),
                                client: AppJson.expo.version,
                                task_id: "",
                                status: "archived",
                                isPallet: false,
                    
                            }, completeForm._id,() => window.location.reload()))}
                            submitFormSave={(status) => status ? submitFormSave(status) : submitFormSave()} 
                            record={submissionSelected} 
                            navigateTo={(page) => console.log('navigateTo')} />
                    </View>
                </ScrollView>
            </View>
        </>
    );
};

export default PromptFormView;

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    commandsContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 20,
        marginBottom: 20,
        marginHorizontal: 15,
    },
    newRecordCommand: {
        flexGrow: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: 50,
        borderRadius: 30,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 0,
        backgroundColor: colors.gray_darken_2,
        marginRight: 1,
    },
    newLodBtn: {
        flexGrow: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: 50,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 0,
        backgroundColor: colors.gray_darken_2,
    },
    commandText: {
        fontSize: 15,
        fontWeight: "700",
        marginLeft: 8,
        color: colors.primary,
    },
    recordsCommand: {
        flexGrow: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 30,
        borderWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        height: 50,
        borderWidth: 0,
        backgroundColor: colors.gray_darken_2,
        marginLeft: 1,
    },
    taskNumber: {
        fontFamily: "Roboto-Bold",
        fontSize: 20,
        paddingLeft: 20,
        paddingVertical: 10,
        color: colors.tasks_number,
    },
    lastUpdated: {
        fontFamily: "Roboto-Bold",
        fontSize: 12,
        color: colors.subtitle,
    },
    subLastUpdated: {
        fontFamily: "Roboto",
        fontSize: 12,
        color: colors.subtitle,
    },
    refreshIcon: {
        fontSize: 15,
        paddingHorizontal: 10,
        color: "gray",
    },
    searchSection: {
        height: 60,
        flexDirection: "row",
        backgroundColor: "#fff",
        alignItems: "center",
        marginTop: 10,
    },
    searchIcon: {
        paddingLeft: 20,
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    openButton: {
        backgroundColor: "#F194FF",
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },

    buttonText: {
        color: "#fff",
        fontFamily: "Roboto",
        fontSize: 14,
    },
    card: {
        backgroundColor: "#fff",
        borderRadius: 6,
        flex: 1,
        marginVertical: 20,
        padding: 30,
        shadowColor: "black",
        shadowOpacity: 0.2,
        shadowOffset: {
            width: 7,
            height: 5,
        },
        shadowRadius: 5,
        minHeight: 600,
    },
    loadingAnimation: {
        height: 20,
        width: 20,
        position: "absolute",
        right: 5,
    },
    container: {
        backgroundColor: "#B3B7BC",
        flex: 1,
        flexDirection: "column",
    },
    containerInside: {
        alignItems: "center",
        backgroundColor: "#B3B7BC",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
    },
    topBar: {
        paddingVertical: 20,
        backgroundColor: colors.primary,
        justifyContent: "center",
        alignItems: "center",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        shadowColor: "black",
        zIndex: 10,
    },
    title: {
        color: "#fff",
        fontSize: 30,
        textAlign: "center",
        fontWeight: "600",
    },
    title2: {
        fontFamily: "Roboto-Bold",
        fontSize: 20,
        paddingLeft: 10,
        paddingVertical: 10,
    },
    subtitle: {
        fontSize: 22,
        fontWeight: "600",
        paddingTop: 10,
        paddingBottom: 10,
        color: colors.primary,
    },

    shipName: {
        paddingTop: 10,
        color: "#fff",
        fontSize: 18,
        textAlign: "center",
        fontWeight: "400",
    },
    wordTwo: {
        fontWeight: "300",
    },
    loadingText: {
        color: "#444",
        marginTop: 5,
    },
    errorText: {
        color: "#444",
        marginLeft: 5,
    },
    input: {
        flex: 1,
        height: 60,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        fontSize: 18,
        backgroundColor: "#fff",
        color: "#90A4AE",
    },
    button: {
        flex: 1,
        backgroundColor: colors.userCommand,
        padding: 0,
        height: 50,
        borderRadius: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 15,
        marginLeft: 15,
        borderRadius: 5,
    },
    buttonCancel: {
        flex: 1,
        backgroundColor: "#ccc",
        padding: 0,
        height: 50,
        borderRadius: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 15,
        marginRight: 15,
        borderRadius: 5,
    },
    text: {
        fontSize: 16,
        fontWeight: "normal",
        paddingTop: 5,
        paddingBottom: 5,
        textAlign: "left",
        color: "#888",
    },
    viewContainer: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10
    },
    viewContainerMax: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10,
        minWidth: 600,
        paddingTop: 50,
        paddingLeft: 50,
        paddingRight: 50,
    },
    viewContainerMax1: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10,
        minWidth: 560,
        maxWidth: 800,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        marginHorizontal: 'auto',
    },
    viewContainerMax2: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10,
        // minWidth: 560,
        // maxWidth: 800,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        marginHorizontal: 'auto',
    },
    viewContainerMax3: {
        flex: 1,
        backgroundColor: '#fff',
        borderRadius: 20,
        marginTop: 10,
        minWidth: 300,
        paddingTop: 50,
        paddingLeft: 10,
        paddingRight: 10,
    },
    labelFirst3: {
        textAlign: 'center',
        width: '100%',
        marginBottom: 30,
        fontSize: 22,
        color: colors.primary,
        fontWeight: 'bold',
        wordBreak: 'break-word',
        wordWrap: 'break-word'
    }
});
