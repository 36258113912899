import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, TouchableHighlight, TextInput, Modal} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import * as DocumentPicker from 'expo-document-picker';
import colors from '../../constants/colors';
// import Modal from 'react-native-modalbox';
import { api } from "../../api";
import { setAttachment } from "../../actions/submissions";
import Swal from "sweetalert2";
import "../styles.css";
import { viewContainer } from "./../functions";
import { GOOGLE_DOCS } from "@env";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      pin: '',
      validPin: 1234,
      isValidPin: false,
      showIncorrectPin: false,
      documentName: '',
      showInstructions: false,
      note: '',
      show: false,
      components: this.props.children,
      docHeight: 600,
      showPinInput: false,
      width: window.innerWidth,
    }
  }

  handleResize = () => {
    console.log("INNER WIDTH", window.innerWidth)
    this.setState({width: window.innerWidth});
  }

  componentDidMount() {
    console.log('Seccion --------------------------------')
    console.log(this.props)
    let windowHeight = window.innerHeight;
    let newDocHeight = (windowHeight * 0.60)
    this.setState({ isValidPin: false, showInstructions: false, docHeight: newDocHeight })
    if (this.props.attachment_type == 'note') {
      this.setState({ showInstructions: true })
    }
    this.getDocSize()
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // window.removeEventListener('resize', () => {
    //   console.log('evento removido')
    // })
    window.removeEventListener('resize', this.handleResize);
  }
  getDocSize() {
    window.addEventListener("resize", () => {
      let windowHeight = window.innerHeight;
      let newDocHeight = (windowHeight * 0.50)
      this.setState({ docHeight: newDocHeight })
    });
  }

  uploadDocument(uri, name) {
    this.updateDocument(uri, name);
    return;
  }

  updateDocument(uri, name) {
    const { id, onChange, formId, setFormAttachment, mediaId } = this.props;
    let data = 'document';
    const dividedName = name?.split(".");
    const extFile = dividedName[dividedName.length - 1];
    if (extFile != "doc") {
      setFormAttachment({
        data,
        uri,
        name,
        formId,
        attachment_type: 'file',
        componentId: id,
        mediaId,
      })
    }
    else
      Swal.fire({
        text: `Please upload the document using the latest word format (.docx) .`,
        confirmButtonText: "Close",
        confirmButtonColor: "#F4981E",
        backdrop: false

      });
  }

  pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync();

    if (result.type == "success") {
      if(result.size > 30000000){
        console.log("looking at result",this.props)
        this.props.setBigFileIsLoading("loading_big_file");
      }
      this.setState({ documentName: result.name })
      this.uploadDocument(result.uri, result.name);
    }
  }

  pickNote = (note) => {
    this.setState({ show: !this.state.show });

  }


  attachmentViewer = (media) => {

    const mediaName = typeof media !== 'undefined' ? media.split("/")[4] : '';
    const mediaExt = mediaName !== '' ? mediaName.split(".")[1] : 'none';
    let windowHeight = window.innerHeight;
    let newDocHeight = (windowHeight * 0.60)

    let mediaUrl = '';
    switch (mediaExt) {
      case 'docx':
      case 'xlsx':
      case 'xls':
      case 'ppt':
      case 'pptx':
        mediaUrl = `${GOOGLE_DOCS}${api + media}&embedded=true`;
        return (
          <iframe
            style={{ justifyContent: 'center', height: newDocHeight, width: 700, alignSelf: 'center', borderColor: '#ddd', borderRadius: 5 }}

            src={mediaUrl}>
          </iframe>
        );
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        mediaUrl = `${api + media}`;
        return (
          <div style={{ justifyContent: 'center', maxWidth: 700, maxHeight: newDocHeight, alignSelf: 'center', borderRadius: 15, overflow: 'auto' }}>
            <img src={mediaUrl} style={{ maxWidth: 700, }} />
          </div>
        );
      case 'mov':
      case 'm4v':
      case 'mkv':
      case 'avi':
      case 'mpeg4':
      case 'webm':
      case 'mp3': 
      case 'mp4':
        mediaUrl = `${api + media}`;
        return (
          <div style={{ justifyContent: 'center', maxWidth: 700, maxHeight: newDocHeight, alignSelf: 'center', borderRadius: 15, overflow: 'auto' }}>
            {
              this.state.modalVisible ?
                <video autoPlay id='video' controls src={mediaUrl} style={{ maxWidth: 700,maxHeight: newDocHeight, }} />
                : null
            }
          </div>
        );
      default:
        mediaUrl = `${api + media}`;
        return (
          <iframe
            style={{ justifyContent: 'center', height: this.state.docHeight, width: 700, alignSelf: 'center', borderColor: '#ddd', borderRadius: 5 }}
            src={mediaUrl}>
          </iframe>
        );
    }
  }

  modal() {
    // console.log("STATE SECTION: ", this.state);
    const { modalVisible, pin, isValidPin, validPin, showIncorrectPin, documentName, showInstructions, showPinInput, show, note } = this.state;
    const { label, media, formId } = this.props;
    let mostrarContendio = false;
    if (this.props.attachment_type == 'note' || this.props.attachment_type == 'file' && typeof media !== 'undefined') {
      mostrarContendio = !mostrarContendio
    }
    // if (Platform.OS == "web") {
    return (
      <Modal
        visible={modalVisible}
        transparent
      >
        <View style={{ backgroundColor: '#f2f2f2', height: '100%' }}>
          <View style={{
            flexDirection: "row",
            backgroundColor: '#f2f2f2',
          }}>
            <View style={{ flex: 1, }} />
            <View style={ viewContainer(this.state.width, styles)} >
            {/* <View className={"viewContainer"} style={{ flex: 1, backgroundColor: '#fff', borderRadius: 20, marginTop: 10, marginBottom: 10 }}> */}
              <View style={{ position: 'relative' }}>
                <TouchableHighlight
                  style={{
                    position: 'absolute',
                    top: -44,
                    right: -44,
                    backgroundColor: '#fff',
                    borderRadius: 20,
                    padding: 10,
                    zIndex: 10,
                  }}
                  activeOpacity={0.5}
                  onPress={() => this.setState({ modalVisible: false })}
                >
                  <Text style={{
                    color: '#000',
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',

                  }}>Close ❌</Text>
                </TouchableHighlight>

                <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{label}</Text>

                {
                  mostrarContendio && this.props.attachment_type == 'note' ?
                    <View style={{
                      justifyContent: 'center',
                      backgroundColor: '#f2f2f2',
                    }}>
                      <Text style={styles.textNote}>{media}</Text>
                    </View>
                    : null
                }
                {
                  mostrarContendio && this.props.attachment_type == 'file' ?

                    <>
                      {this.attachmentViewer(media)}
                    </> : null
                }
                {
                  documentName != "" &&
                  <Text style={{ fontSize: 15, flex: 0.2 }}>Media to be uploaded: {documentName}</Text>
                }
                {
                  !isValidPin &&
                  <>

                    <Modal
                      visible={showPinInput}
                      style={styles.modalPin}
                      transparent
                    >
                      <View style={styles.modalPin}>
                        {
                          showIncorrectPin &&
                          <Text style={{ fontSize: 15, flex: 0.2, color: colors.error }}>Incorrect PIN, please verify it and try again</Text>
                        }
                        <TextInput
                          secureTextEntry={true}
                          value={pin}
                          style={styles.input}
                          placeholder={"Security PIN"}
                          onChangeText={(val) => {
                            this.setState({ pin: val });
                          }}
                        />
                        <TouchableHighlight
                          style={styles.button}
                          onPress={() => {
                            if (pin == validPin) {
                              this.setState({ isValidPin: true, showIncorrectPin: false });
                            }
                            else {
                              this.setState({ showIncorrectPin: true });
                            }



                          }}
                        >
                          <Text style={styles.textButton}>Valdate PIN</Text>
                        </TouchableHighlight>

                        <TouchableHighlight
                          style={styles.button}
                          onPress={() => {
                            this.setState({ showPinInput: false })
                          }}
                        >
                          <Text style={styles.textButton}>Close</Text>
                        </TouchableHighlight>
                      </View>

                    </Modal>



                    <TouchableHighlight
                      style={styles.button}
                      onPress={() => {                      
                          this.setState({ showPinInput: true })
                      }}
                    >
                      <Text style={styles.textButton}>Edit Instructions</Text>
                    </TouchableHighlight>
                  </>
                }
                    <Modal
                      visible={show}
                      style={styles.modalNote}
                      transparent
                    >
                      <View style={styles.modalNote}>
                        
                        <TextInput
                          value={note}
                          style={styles.inputNote}
                          placeholder={"Enter a note"}
                          onChangeText={(val) => {
                            this.setState({ note: val });
                          }}
                        />
                        <TouchableHighlight
                          style={styles.button}
                          onPress={() => {
                            const { id, formId, setFormAttachment, mediaId } = this.props;
                            setFormAttachment({
                              val: this.state.note,
                              formId,
                              media: this.state.note,
                              attachment_type: 'note',
                              componentId: id,
                              mediaId,
                            })
                          }}
                        >
                          <Text style={styles.textButton}>Save Note</Text>
                        </TouchableHighlight>

                        <TouchableHighlight
                          style={styles.button}
                          onPress={() => {
                            this.setState({ show: false })
                          }}
                        >
                          <Text style={styles.textButton}>Cancel</Text>
                        </TouchableHighlight>
                      </View>

                    </Modal>

                {
                  isValidPin &&
                  <>
                    <TouchableHighlight
                      style={styles.button}
                      onPress={this.pickDocument}
                    >
                      <Text style={styles.textButton}>Add Document +</Text>
                    </TouchableHighlight>
                    <TouchableHighlight
                      style={styles.button}
                      onPress={this.pickNote}
                    >
                      <Text style={styles.textButton}>Add a note +</Text>
                    </TouchableHighlight>
                    
                  </>
                }


              </View>
            </View>
            <View style={{ flex: 1 }}>

            </View>
          </View>
          {/* {console.log(this.props.attachment_type)} */}

        </View>
      </Modal>);

  }


  render() {
    const { label, showSeparator, children, isLast, media, formSubmission } = this.props;
    const iconColor = typeof media !== 'undefined' ? 'green' : 'gray';
    {/* return <View style={isLast ? styles.containerLast :styles.container}> */ }
    return <View style={isLast ? styles.containerLast : styles.container}>
      {/* {console.log(label)} */}
      {this.modal()}
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', wordBreak: 'break-word', wordWrap: 'break-word', }}>
        <Text style={showSeparator ? styles.labelFirst : styles.label}>{''}</Text>
        {formSubmission ?
          null
          :
          <TouchableOpacity onPress={() => {
            console.log(this.props)
            this.setState({ modalVisible: true })
          }} >
            <Icon name="help-box" style={{ color: iconColor, fontSize: 30, paddingRight: 10}} />
          </TouchableOpacity>}

      </View>
      {children}
    </View>;
  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
    }
  },
  (dispatch, props) => {
    return {
      setFormAttachment: (params) => dispatch(setAttachment(params)),
    };
  }
)(Section);

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#f4981e',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#f4981e',
    padding: 10,
    margin: 10,
    width: '100%'

  },
  textButton: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  input: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    padding: 10,
    margin: 10,
    fontSize: 18,
    color: colors.primary,
  },
  inputNote: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    padding: 10,
    margin: 10,
    fontSize: 18,
    color: colors.primary,
    width: '100%'
  },
  textNote: {
    fontSize: 18,
    color: colors.primary,
    margin: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    textAlign: 'center',

  }, labelFirst: {
    fontSize: 20,
    color: colors.primary,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  label: {
    fontSize: 20,
    color: colors.primary,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderBottomWidth: 10,
    borderBottomColor: "#F0F0F0",
  },
  containerLast: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  modalPin: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#eee',
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    height: 300,
    margin: 'auto',
    padding: 30,
    width: 300
  },
  modalNote: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#eee',
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    height: 300,
    margin: 'auto',
    padding: 30,
    width: 500
  },
  viewContainerMax: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    marginBottom: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    marginBottom: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    marginBottom: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    marginBottom: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },


});
