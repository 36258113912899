import React, { Component } from "react";
import { View, StyleSheet, Text, TouchableHighlight, Platform, TouchableOpacity } from "react-native";

import Section from "./components/section";
import DateTime from "./components/datetime";
import InputWithLabel from "./components/inputwithlabel";
import DropDown from "./components/dropdown";
import Input from "./components/input";
import Picture from "./components/picture";
import TextArea from "./components/textarea";
import Signature from "./components/signature";
import YesNo from "./components/yesno";
import YesNoAll from "./components/yesnoall";
import Counter from "./components/counter";
import Lotcode from "./components/lotcode";
import Document from "./components/document";
import colors from "../constants/colors";
import ChatAI from "./components/chatAI";

function BtnLink({ componentLink }) {
  return (
    <TouchableHighlight style={styles.btnLink}
      underlayColor={colors.secondary}

      onPress={() => {
        window.open(componentLink, '_blank')
      }}
    >
      <Text style={styles.buttonText}>
        SEE LINK
      </Text>
    </TouchableHighlight>
  )
}
export default class FormComponent extends Component {
  renderComponent = (
    id,
    type,
    label,
    first,
    initial,
    onChange,
    options,
    checkboxkey,
    status,
    lotCodeType,
    isIssuable,
    onSubmitIssue,
    placeholder,
    componentLink,
    enabledLink,
    submissionSelected,
    lotConsecutive,
    showLotGenerator,
    companyTimezone,
    position,
    width,
    openIssue,
    defaultComponent,
    secondDatePicker,
    promptMessages,
    showInventorySearch,
    showFinishedGoodsSearch,
    showWorkInProgressSearch,
    showItemsSearch,
    showPackagingSearch,
    defaultLabel,
    isRecipe,
    submission,
    itemInvoice_id,
    isShipmentLog,
    resetInventorySearch,
    showWipSearch,
    components,
    inventoryTypes,
    searchClients,
    section_id,
    searchSuppliers,
    isInvoice,
    isInventoryTransfer,
    inventoryLocations
  ) => {
    switch (type) {
      case "section":
        return <Section showSeparator={!first} label={label} />;
      case "datetime":
        return (
          <>
            <DateTime
              id={id} initial={initial}
              label={label}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              companyTimezone={companyTimezone}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        )
      case "inputlabel":
        return (
          <>
            <InputWithLabel
              id={id}
              initial={initial}
              label={label}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "input":
        return (
          <React.Fragment>
            <Input
              id={id}
              initial={initial}
              label={label}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              position={position}
              width={width}
              defaultLabel={defaultLabel}
              isRecipe={isRecipe}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </React.Fragment>
        );
      case "dropdown":
        return (
          <>
            <DropDown
              id={id}
              label={label}
              initial={initial}
              options={options}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "picture":
        return (
          <>
            <Picture
              id={id}
              label={label}
              initial={initial}
              onChange={(id, val) => onChange(id, val, false, position)}
              placeholder={placeholder}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "textarea":
        return (
          <>
            <TextArea
              id={id}
              label={label}
              initial={initial}
              onChange={(id, val) => onChange(id, val, false, position)}
              placeholder={placeholder}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "signature":
        return (
          <>
            <Signature
              id={id}
              label={label}
              initial={initial}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              widthS={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "yesno":
        return (
          <>
            <YesNo
              id={id}
              label={label}
              key={checkboxkey}
              onChange={(id, val) => onChange(id, val, false, position)}
              placeholder={placeholder}
              initial={initial}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "yesnoall":
        return (
          <>
            <YesNoAll
              id={id}
              label={label}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              initial={initial}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "timer":
        return (
          <>
            <Counter
              id={id}
              label={label}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              initial={initial}
              autoStart={false}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "timerauto":
        return (
          <>
            <Counter
              id={id}
              label={label}
              placeholder={placeholder}
              onChange={(id, val) => onChange(id, val, false, position)}
              initial={initial}
              autoStart={true}
              status={status}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "lotcode":
      case "customlotcode":
        return (
          <>
            <Lotcode
              id={id}
              placeholder={placeholder}
              initial={initial ? initial : ""}
              label={label}
              customlot={lotCodeType}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              submissionSelected={submissionSelected}
              lotConsecutive={lotConsecutive}
              showLotGenerator={showLotGenerator}
              width={width}
              defaultComponent={defaultComponent}
              showInventorySearch={showInventorySearch}
              showFinishedGoodsSearch={showFinishedGoodsSearch}
              showWorkInProgressSearch={showWorkInProgressSearch}
              position={position}
              defaultLabel={defaultLabel}
              isShipmentLog={isShipmentLog}
              options={options}
              resetInventorySearch={resetInventorySearch}
              isRecipe={isRecipe}
              submission={submission}
              showWipSearch={showWipSearch}
              components={components}
              section_id={section_id}
              isInvoice={isInvoice}
              isInventoryTransfer={isInventoryTransfer}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "inventory":
        return (<>
          <Lotcode
            id={id}
            initial={initial.lotcode}
            placeholder={placeholder}
            label={label}
            onChange={(id, val) => onChange(id, val, "lotcode", position)}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            isCompleted={status == "complete"}
            submissionSelected={submissionSelected}
            lotConsecutive={lotConsecutive}
            showLotGenerator={showLotGenerator}
            width={width}
          />
          <Input id={id} placeholder={placeholder} initial={initial.productname} label="Product Name"
            onChange={(id, val) => onChange(id, val, "productname", position)}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            width={width}
            isCompleted={status == "complete"} />
          <Input id={id} placeholder={placeholder} initial={initial.quantity} label="Quantity"
            onChange={(id, val) => onChange(id, val, "quantity", position)}
            isIssuable={isIssuable}
            onSubmitIssue={onSubmitIssue}
            width={width}
            isCompleted={status == "complete"}
          />
          {enabledLink ?
            <BtnLink componentLink={componentLink} />
            : null}
        </>);
      case "document":
        return (
          <>
            <Document
              id={id}
              label={label}
              placeholder={placeholder}
              initial={initial}
              onChange={(id, val) => onChange(id, val, false, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
      case "chatAI":
        return (
          <React.Fragment>
            <ChatAI
              id={id}
              initial={initial}
              label={label}
              placeholder={placeholder}
              onChange={(id, val, subtype) => onChange(id, val, subtype, position)}
              isIssuable={isIssuable}
              onSubmitIssue={onSubmitIssue}
              isCompleted={status == "complete"}
              promptMessages={promptMessages}
              width={width}
            />
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </React.Fragment>
        );
      default:
        return (
          <>
            <TouchableOpacity onPress={() => onChange(id, "1")}>
              <Text>{id}</Text>
              <Text>{type}</Text>
              <Text>{label}</Text>
            </TouchableOpacity>
            {enabledLink ?
              <BtnLink componentLink={componentLink} />
              : null}
          </>
        );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.type === "yesno") {
    }
  }

  render() {
    const {
      id,
      type,
      label,
      first,
      onChange,
      initial,
      options,
      checkboxkey,
      status,
      customlot,
      isIssuable,
      onSubmitIssue,
      placeholder,
      componentLink,
      enabledLink,
      submissionSelected,
      lotConsecutive,
      showLotGenerator,
      companyTimezone,
      position,
      width,
      openIssue,
      defaultComponent,
      secondDatePicker,
      promptMessages,
      showInventorySearch,
      showFinishedGoodsSearch,
      showWorkInProgressSearch,
      showItemsSearch,
      showPackagingSearch,
      defaultLabel,
      isRecipe,
      submission,
      itemInvoice_id,
      isShipmentLog,
      resetInventorySearch,
      showWipSearch,
      components,
      inventoryTypes,
      searchClients,
      section_id,
      searchSuppliers,
      isInvoice,
      isInventoryTransfer,
      inventoryLocations
    } = this.props;
    return (
      <View style={styles.container}>
        {this.renderComponent(
          id,
          type,
          label,
          first,
          initial,
          onChange,
          options,
          checkboxkey,
          status,
          customlot,
          isIssuable,
          onSubmitIssue,
          placeholder,
          componentLink,
          enabledLink,
          submissionSelected,
          lotConsecutive,
          showLotGenerator,
          companyTimezone,
          position,
          width,
          openIssue,
          defaultComponent,
          secondDatePicker,
          promptMessages,
          showInventorySearch,
          showFinishedGoodsSearch,
          showWorkInProgressSearch,
          showItemsSearch,
          showPackagingSearch,
          defaultLabel,
          isRecipe,
          submission,
          itemInvoice_id,
          isShipmentLog,
          resetInventorySearch,
          showWipSearch,
          components,
          inventoryTypes,
          searchClients,
          section_id,
          searchSuppliers,
          isInvoice,
          isInventoryTransfer,
          inventoryLocations

        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  btnLink: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    overflow: "hidden",
    marginLeft: 10,
    marginRight: 10,

  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "300",
    fontFamily: "Roboto",
  }
});