import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment-timezone";

export default class DropDown extends Component {
  state = {
    isVisible: false,
    selected: null,
  };
  componentDidMount() {
    const { openList, initialOption } = this.props;
    this.setState({ isVisible: openList });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.updated != this.props.updated || nextProps.reset != this.props.reset) {
      this.setState({ selected: nextProps.updated ? nextProps.updated : [] });
    }
    if (nextProps.openList != this.props.openList) {
      this.setState({ isVisible: nextProps.openList });
    }
  }
  renderDetailItem(item) {
    const { defaultLabel } = this.props;
    var amount = 0;
    var productionDate = "";
    if (item?.historial) {
      item.historial.map((batch) => {
        productionDate = batch.production_date;
        amount = batch.amount + amount;
      });
    }
    if (amount % 1 > 0) {
      amount = amount.toFixed(3);
    }
    productionDate = moment(productionDate).format("YYYY-MM-DD")
    return `${item?.lot ? item?.lot : defaultLabel} - ${amount} - ${productionDate}`
  }
  render() {
    const { isVisible, selected, openList } = this.state;
    const { options, initialOption, onSelect, defaultComponent, defaultLabel, allLabel, isRecipe, width } = this.props;
    const selectedOption = selected ? selected : initialOption;
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.button}
          disabled={!options || options?.length == 0}
          onPress={() => {
            if (!defaultComponent) {
              this.setState({ isVisible: !isVisible })
            }
          }}
        >
          <View style={{ width: width == 'mini' ? '80%' : '90%' }}>
            {
              (selectedOption && typeof selectedOption == "object") && selectedOption.map((selected) => {
                return <Text style={{ ...styles.label, paddingLeft: 10, paddingVertical: 10 }}>{selected == "default_lot" ? allLabel : selected?.lot || selected?.lot == "" ? this.renderDetailItem(selected) : defaultLabel}</Text>
              })
            }
          </View>
          <MaterialCommunityIcons
            name="chevron-down"
            size={30}
            color="#9B9B9B"
            style={styles.icon}
          />
        </TouchableOpacity>
        {isVisible ? (
          <View style={styles.options}>
            <ScrollView>
              <TouchableOpacity
                style={styles.item}
                key={0}
                onPress={() => {
                  var selected = ["default_lot"];
                  onSelect(selected);
                  this.setState({ selected: selected, isVisible: false });
                }}
              >
                <Text style={styles.label}>{allLabel}</Text>
              </TouchableOpacity>
              {options.map((option, idx) => (
                <TouchableOpacity
                  style={styles.item}
                  key={idx}
                  onPress={() => {
                    var idx = -1;
                    var selected = [];
                    if (typeof selectedOption == "object") {
                      idx = selectedOption.findIndex(selectedO => selectedO?.lot == option?.lot);
                      selected = selectedOption;
                    }
                    if (isRecipe) {
                      if (idx >= 0) {
                        selected.splice(idx, 1);
                      } else {
                        selected.push(option);
                      }
                    } else {
                      selected = [option];
                    }
                    onSelect(selected);
                    this.setState({ selected: selected, isVisible: false });
                  }}
                >
                  <Text style={styles.label}>{option?.lot || option?.lot == "" ? this.renderDetailItem(option) : defaultLabel}</Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    borderWidth: 1,
    borderColor: "#e7e7e7",
    borderRadius: 5,
    paddingLeft: 10,
    height: 60,
    backgroundColor: 'lightgray'
  },
  label: {
    fontSize: 16,
    color: "#333",
    fontWeight: "200",
    fontFamily: "Roboto",
    wordBreak: 'break-all',
    width: '100%'
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 5,
  },
  options: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderTopLeftRadius: 0,
    paddingBottom: 5,
  },
  item: {
    paddingLeft: 10,
    paddingVertical: 10,
  },
});
