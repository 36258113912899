import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import Swal from "sweetalert2";
import colors from "../../constants/colors";

var filterInterval;

export default class Input extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
      height: 30,
      dialogVisible: false,
    };
  }
  
  batchSizeAlert() {
    const { onChange, id } = this.props;
    const { yieldValue, value } = this.state;
    clearInterval(filterInterval);
    Swal.fire({
      text: "Are you sure you want to increase/decrease the ingredients and yield of this recipe?.",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: colors.orange,
      backdrop: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ yieldValue: value });
        onChange(id, value);
      } else {
        this.setState({ value: yieldValue });
      }
    });

  }
  render() {
    const { value } = this.state;
    
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, width, position, isRecipe, defaultLabel } = this.props;
    return (
      <>
        <NewIssue 
          visible={this.state.dialogVisible} 
          id={id} 
          label={label} 
          onSubmitIssue={onSubmitIssue} 
          handleClose={()=>this.setState({dialogVisible:false})} 
        />

        <View style={styles.container, {
          paddingTop: width ? 0 : 5
        }}>
          <Text style={styles.label}>{this.props.label}</Text>
          {
            (isIssuable && isCompleted) && 
            <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
              this.setState({dialogVisible:true});
            }}>
              <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
            </TouchableOpacity>
          }
          <TextInput
            value={value}
            onContentSizeChange={(event) => {
              if(event.nativeEvent.contentSize.height == 0){
                if(this.props.placeholder == ''){
                  this.setState({height: 30})
                }
                this.setState({height: event.nativeEvent.contentSize.height});
              }else{
                this.setState({height: event.nativeEvent.contentSize.height});
              }
              }}
              style={styles.input,{ 
              borderColor: "#e7e7e7",
              borderRadius: 5,
              borderWidth: 1,
              color: "#424242",
              fontFamily: "Roboto",
              fontSize: 20,
              fontWeight: "400",
              padding: 5,
              height: this.state.height,
              fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic': 'normal' : 'normal',
              overflow: 'visible',
            }}
              multiline={true}
            placeholder={this.props.placeholder}
            placeholderTextColor={'rgb(0, 47, 108)'}
            onChangeText={(val) => {
              if (isRecipe && defaultLabel == "yield") {
                var formated = val.trim();
                if (formated == '' && this.props.placeholder == '') {
                  clearInterval(filterInterval);
                  this.setState({ value: formated, height: 30 });
                } else {
                  if (formated == '') {
                    clearInterval(filterInterval);
                    this.setState({ value: formated, yieldValue: formated });
                  } else {
                    this.setState({ value: formated }, () => {
                      clearInterval(filterInterval);
                      filterInterval = setInterval(() => this.batchSizeAlert(), 2500);
                    });
                  }

                }
              }else {
                if(val == '' && this.props.placeholder == ''){
                  this.setState({ value: val, height: 30 });
  
                }else{
                  this.setState({ value: val });
  
                }
                onChange(id, val);
              }
            }}
          />
        </View>
      </>

    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingBottom: 10,
    paddingHorizontal: 10,
    paddingTop: 5,
    flex: 1,
  },
  input: {
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderWidth: 1,
    color: "#424242",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "400",
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
});
