export default {
  primary: "#002f6c",
  primary_light: "#20B2AA33",
  secondary: "#263238",
  accent: "#0084BF",
  error: "#DB3A3A",
  gray: "#F8F8F8",
  pink: "#EC407A",
  ambar: "#FBC02D",
  cyan: "#80DEEA",
  yellow: "#fac818",  
  orange: "#f4981e",
  gray_darken_2: "#CFD8DC",
  gray_darken_3: "#455A64",  
  blue_gray: "#002f6c",
  success: "#56bf72",
  black: "#000",  
  title: "#000000",
  subtitle: "#a7aaaa",
  description: "#a7aaaa",
  disabled: "#a7aaaa",
  userCommand: "#f4981e",
  userCommandText: "#ffffff",
  commandSecondary: "#CFD8DC",
  commandSecondayText: "#002f6c",
};
