import React, { Component } from "react";
import { View, Image,  } from "react-native";
import { connect } from "react-redux";
import { api } from "../../api";
import { getImageSize } from 'react-image-size';
class LogoTitle extends Component{
  constructor(props) {
    super(props);
    this.state = {
      dimensions:null,
      ratio:null,
      widthCalculated:null
    }
  }

  fetchImageSize = async (logo) =>  {
   
        const dimensionsPromise = await getImageSize(logo);
        console.log(dimensionsPromise)
        this.setState({dimensions:dimensionsPromise})
        let ratio = dimensionsPromise.height/dimensionsPromise.width
        console.log(ratio)
        this.setState({widthCalculated:(75/ratio) });
        return dimensionsPromise
    
  }
    render(){
        const {company_logo} = this.props;
        console.log(api+company_logo)
        let logo = api+company_logo;
        
        if(this.state.dimensions == null){
          this.fetchImageSize(logo)
        }
           

        
        /*const dimensionsPromise = getImageSize(api+company_logo).then(result => {
          return result;
        });*/ 
      
        //console.log(dimensionsPromise.PromiseResult)
        
        
        
        return (
            <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              {
                company_logo && 
                  <Image
                    style={{ width: this.state.widthCalculated?this.state.widthCalculated:150, height: 70 }}
                    source={api + company_logo}
                  />
              }
            </View>
        );
    }
}

export default connect((state) => {
  return {
    company_logo: state.user.get('company_logo'),
  }
}, (dispatch, props) => {
  return {
  };
})(LogoTitle);