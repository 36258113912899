import 'react-native-gesture-handler';
import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import Login from './components/login';
import Forgot from './components/forgot';
import Register from './components/register';
import MainView from './components/mainview';
import RecordsView from './components/records';
import TaskDetail from './components/taskDetail';
import FormView from './components/form';
import NewTask from './components/newTask';
import NewLogTask from './components/newLogTask';
import GroupView from './components/groupView';
import SalesGuide from './components/salesGuide';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import colors from "./constants/colors";
import { logout } from "./actions/login";
import {
  Image, View, TouchableOpacity, Text, NativeModules, Platform
} from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import alerts from './components/components/alert';
import TitleBar from "./components/widgets/NTitlebar";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import LogoTitle from './components/widgets/companyLogo';
// import api from './actions/index.js';
import { qaHost } from './api';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


function LoginScreen({ navigation }) {
  return (<Login navigation={navigation} />);
}
function SalesScreen({ navigation }) {
  return (<SalesGuide navigation={navigation} />);
}
function ForgotScreen({ navigation }) {
  return (<Forgot navigation={navigation} />);
}
function RegisterScreen({ navigation }) {
  return (<Register navigation={navigation} />);
}
function MainViewScreen({ navigation }) {
  return (<MainView navigation={navigation} />);
}
function RecordsViewScreen({ navigation }) {
  return (<RecordsView navigation={navigation} />);
}

function TaskDetailScreen({ navigation }) {
  return (<TaskDetail navigation={navigation} />);
}
function FormViewScreen({ route, navigation }) {
  return (<FormView navigation={navigation} formId={route.params.formId} title={route.params.title} isSubmission={route.params.submission} newReport={route.params.newReport} task_id={route.params.task_id} log_group_id={route.params.log_group_id} promptMessages={route.params.promptMessages} isPallet={route.params.isPallet} isReceivingLog={route.params.isReceivingLog} isOrder={route.params.isOrder} isRecipe={route.params.isRecipe} defaultYield={route.params.defaultYield} isInvoice={route.params.isInvoice} isShipmentLog={route.params.isShipmentLog} isReconciliationLog={route.params.isReconciliationLog} isInventoryTransfer={route.params.isInventoryTransfer}/>);
}
function NewTaskScreen({ navigation }) {
  return (<NewTask navigation={navigation} />);
}
function NewLogTaskScreen({ navigation }) {
  return (<NewLogTask navigation={navigation} />);
}
function GroupViewScreen({ navigation }) {
  return (<GroupView navigation={navigation} />);
}

function Logout({ navigation }) {
  const dispatch = useDispatch();
  return (
    <View style={{ display: 'flex', flexDirection: "row" }}>
      <View style={{ display: 'flex', flex: 1, justifyContent: "flex-end", flexDirection: "row", alignItems: 'flex-end', }}>
        <Text style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>Dashboard</Text>
      </View>
      <View style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: "row", alignItems: 'flex-end' }}>
        <TouchableOpacity
          style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: "row" }}
          onPress={() => {
            Swal.fire({
              title: "Leave Form",
              text: "If you leave this form your data will be lost. Are you sure?",
              showCancelButton: true,
              cancelButtonText: "Cancel",
              confirmButtonText: "OK",
              confirmButtonColor: "#F4981E",
              backdrop: false
            }).then((result) => {
              if (result.isConfirmed) {
                if (Platform.OS === 'web') {
                  //location.reload();
                  window.location.reload(false);
                } else {
                  dispatch(logout());
                  NativeModules.DevSettings.reload();
                }
              }
            });
          }}

        >
          <Text style={{ color: "white", }}>Logout</Text>
          <MaterialCommunityIcons
            name="account-circle"
            size={22}
            color="#fff"
            style={{
              height: 24,
              width: 24,
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}
const Stack = createStackNavigator();
const linking = {
  prefixes: [Linking.createURL('/'), qaHost],
  config: {
    screens: {
      salesGuide: {
        path: 'salesGuide/:id',
        parse: {
          id: (id) => `${id}`,
        },
      }
    },
  },
};
export default function App() {

  return (
    <Provider store={store}>
      {/* <NavigationContainer linking={linking}>
        <Stack.Navigator>
          <Stack.Screen name="salesGuide"
            options={{
              headerTitle: props => <LogoTitle {...props} />,
              title: "Sales Guide",
              headerStyle: {
                backgroundColor: colors.primary,
              }, headerTintColor: '#ffffff',
              headerTitleStyle: {
                fontWeight: 'bold'
              }
            }}
            component={SalesScreen} />
        </Stack.Navigator>
      </NavigationContainer> */}
      <SalesGuide />
    </Provider>
  );
}