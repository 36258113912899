import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Image,
  View,
  StyleSheet,
  Text,
  TextInput,  
  Switch,
  TouchableOpacity,
  Alert,  
  ScrollView,
  Platform
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "../constants/colors";
import {
  getMenuImages,
  navigateMenu,
} from "../actions/menu";
import { logout, authTokensUpdated } from "../actions/login";
import { forgotpassword } from "../actions/forgotpassword";


class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  validateEmail = () => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.email.toLowerCase());
  };

  componentDidMount() {
    this.props.dispatch(logout());
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    // const refresh = urlParams.get('refresh');
    // if(token && refresh)
    if(token ){
      this.props.setToken({
        token: urlParams.get('token'),
        // refresh: urlParams.get('refresh'),
        refresh: '' 
      });
      urlParams.delete('token');
      urlParams.delete('refresh');
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.message !== newProps.message) {
      this.setState ({message: newProps.message})
    }
  }

  sendPasswordResetRequest = () => {
    if (this.validateEmail()) {
      this.setState({
        email_error: false,
        message: "Sending new password reset request...",
      });
      let obj = {};          
          obj.email = this.state.email;
          this.props.forgotpassword(this.props.router, obj);
          
      
    } else {
      this.setState({
        email_error: true,
        message: "Please enter a valid email",
      });
    }

  };

  render() {
    const { email } = this.state;
    
    return (
      <ScrollView contentContainerStyle={styles.container}>

        <View
          style={{
            flex: 7,
            display: 'flex', alignItems: 'center'
          }}
        >
          <View style={styles.fixedContainer}>
            <View style={styles.fixedContainerHT}>
              <MaterialCommunityIcons
                name="email"
                size={32}
                color="#d00"
                style={styles.icon}
              />
              <TextInput
                value={email}
                style={styles.input}
                placeholder="Email"
                autoCapitalize="none"
                onChangeText={(val) => {
                  this.setState({ email: val });
                }}
              />
            </View>
            <View style={styles.fixedContainerHB}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  this.sendPasswordResetRequest();                                 
                }}
              >
                <View style={{display: 'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{ color: "white", fontSize: 18}}>Reset Password</Text>
                </View>
              </TouchableOpacity>
              <View>
                <Text style={{textAlign: 'center',  fontWeight: "bold", color: "#002f6c", paddingTop: 15}}>{this.state.message}</Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
}

export default connect(
  (state) => {
    return {  
      errorVisible: state.forgotpassword.get("errorVisible"),
      message: state.forgotpassword.get("message"),
    };
  },
  (dispatch, props) => {
    return {
      forgotpassword: (router, email) => dispatch(forgotpassword(router, email)),
      navigateMenu: () => {
        dispatch(navigateMenu());
      },
      getMenuImages: () => dispatch(getMenuImages()),
      setToken : (tokens) => dispatch(authTokensUpdated(tokens)),
      dispatch,
    };
  }
)(Forgot);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: "#F3F3F3",
  },
  topBar: {
    flex: 1,
    backgroundColor: colors.primary,
    justifyContent: "center",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 2,
    shadowOpacity: 0.2,
    shadowColor: "black",
    paddingVertical: 0,
  },
  bottomBar: {
    flexDirection: "column",    
    alignContent: "flex-end",
  },
  title: {
    paddingTop: 15,
    color: "#fff",
    fontSize: 40,
    textAlign: "center",
    backgroundColor: "transparent",
    fontFamily: "Roboto-Bold",
  },
  fixedContainer: {
    width: 300,
    backgroundColor: "white",
    borderRadius: 6,
    elevation: 3,
    flexDirection: "column",
    padding: 20,
    marginHorizontal: 20,
    marginTop: 50,
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowRadius: 3,
    shadowOffset: {
      width: 1,
      height: 1,
    },
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  fixedContainerHB: {
    marginTop: 5,
  },
  innerView: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 75,
    paddingRight: 75,
  },
  btn: {
    margin: 10,
    backgroundColor: "#3B5998",
    padding: 10,
  },
  text: {
    fontSize: 22,
  },
  input: {
    flex: 1,
    height: 58,
    paddingLeft: 55,
    marginLeft: 0,
    paddingBottom: 0,
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#444",
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
    borderRadius: 6,
  },
  icon: {
    position: "absolute",
    left: 12,
    color: colors.gray_darken_2,
  },
  button: {
    backgroundColor: colors.orange,
    padding: 0,
    width: "100%",
    height: 58,
    borderRadius: 6,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
  },
});
