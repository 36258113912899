import React, { Component } from "react";
import {
  Alert,
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { Table, Row } from 'react-native-table-component';
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { submitForm, getForm, beginSubmit, checkRecordStatus, showStatusError, checkLogStatus, showLogStatusError } from "../actions/menu";
import { sendSubmit, setBigFileIsLoading, loadingIssue, setIsSubmission, setSubmissionSelected } from "../actions/submissions";
import { setStatusChange, unsetStatusChange } from "../actions/records";
import { setIsLogGroup, getGroupDetail, setLogGroupsSubAction } from "../actions/loggroups";
import moment from "moment-timezone";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FormComponent from "./component";
import uuid from "uuid";
import LogDialog from "./dialogs/log";
import Section from "./components/section";
import RecordActions from "./widgets/recordActions";
import colors from "../constants/colors";
import alerts from "./components/alert"
import StorageService from "../services/storage";
import PaymentInfo from './dialogs/paymentInfo';
import Swal from "sweetalert2";
import "./styles.css";
import { viewContainer } from "./functions";
import { createNewTask } from "../actions/tasks";
import { getSalesGuide } from "../actions/login";
import StepIndicator from 'react-native-step-indicator';
import PromptFormView from "./promptFormView";
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: {},
      lastStatus: false,
      checkboxkey: 0, // increment by 1 each time yesnoall is clicked
      components: [],
      choicelist: {},
      uuid: "",
      derived: false,
      autosave: false,
      tmpIssues: [],
      showPaymentInfo: false,
      callOnSave: false,
      lotConsecutive: 1,
      formLoaded: false,
      width: window.innerWidth,
      sectionIndex: 0,
      errorMessage: "",
      active: 0,
      form: null,
      rerenderTable: false,
      palletComponentChanged: false,
      itemComponentChanged: false,

    };
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    if (!this.props.form_id) {
      this.setState({ errorMessage: "Sorry, this link needs a form id, please add one" });
    } else {
      this.props.getSalesGuide(this.props.form_id, (res) => {
        if (res.message) {
          Swal.fire({
            title: res.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "#F4981E",
            backdrop: false
          })
          this.setState({ errorMessage: res.message });
        } else {
          setTimeout(() => {
            this.getForm();
          }, 100)
        }
      });
    }

    window.addEventListener('resize', this.handleResize);
  }

  getForm() {
    this.props.getForm(this.props.form_id, (res) => {
      setTimeout(() => {
        this.createTask();
        this.setState({ formLoaded: true });
        if (res?.promptMessages) {
          this.setState({ form: res });
        }
      }, 100);
    });
  }

  createTask() {
    const { formName, formId } = this.props;
    let start = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");

    let object = {
      name: formName,
      description: formName,
      forms_id: formId,
      start_date: start,
      expiration_date: moment(start).add(1, "day").format("YYYY-MM-DD HH:mm"),
      repeat_frequency: "",
      status: "archive"
    };
    this.props.createNewTask(object);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  checkLotSubmissions() {
    const { submissionSelected } = this.props;
    let num_array = [];
    if (submissionSelected) {
      let sub_id = submissionSelected._id.slice(-8);
      for (let component of submissionSelected.components) {
        let obj = submissionSelected.submission[component.id];
        if (obj) {
          obj = obj.toString();
          if (component.type === "lotcode" || component.type === "customlotcode") {
            if (obj.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
          if (component.type === "inventory") {
            if (obj.lotcode && obj.lotcode.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
        }
      }
      var max = Math.max(...num_array);
      var min = Math.min(...num_array);
      if (max && max > 0) {
        this.setState({ lotConsecutive: max + 1 })
      }
    }
  }

  setCompleteStatus() {
    const { submission } = this.state;
    if (submission.status) {
      this.setState({ lastStatus: submission.status })
    }
    submission.status = "complete";
    this.setState({ submission: submission }, () => {
      this.submitForm("complete");
    });
  }

  saveForm = () => {
    this.setState(
      {
        autosave: true,
      },
      () => {
        this.props.autoSave(
          this.state.components,
          this.state.choicelist,
          this.state.submission,
          this.props.newReport ? uuid.v1() : this.state.uuid,
          this.props.task_id,
          this.state.autosave
        );
      }
    );
  };

  getComponentById(id) {
    const { components } = this.state;
    var r = null;
    for (var i = 0; i < components.length; i++) {
      if (components[i]["id"] == id) return components[i];
    }
  }

  onComponentChange(id, value, subtype = false, position) {
    const { submission, components, lotConsecutive, itemComponentChanged } = this.state;
    const { isPallet, isReceivingLog, isOrder, isRecipe, isInvoice, isShipmentLog, defaultYield, isReconciliationLog, companyTimezone, isInventoryTransfer } = this.props;

    // let newSubmission = Object.assign({}, submission);
    let newSubmission = JSON.parse(JSON.stringify(submission));

    let checkboxkey = this.state.checkboxkey;
    var c = this.getComponentById(id);
    if (typeof c != "undefined") {
      if (subtype) {
        if (typeof newSubmission[c.id] != "undefined") {
          if (newSubmission[c.id] == "") {
            newSubmission[c.id] = [];
          }
          if (position || position == 0) {
            if (newSubmission[c.id][position])
              newSubmission[c.id][position][subtype] = value;
            else {
              newSubmission[c.id][position] = {};
              newSubmission[c.id][position][subtype] = value;
            }
          } else {
            newSubmission[c.id] = {};
            newSubmission[c.id][subtype] = value;
          }
        } else {
          newSubmission[c.id] = {};
          if (position || position == 0) {
            if (newSubmission[c.id][position])
              newSubmission[c.id][position][subtype] = value;
            else {
              newSubmission[c.id][position] = {};
              newSubmission[c.id][position][subtype] = value;
            }
          } else {
            newSubmission[c.id][subtype] = value;
          }
        }
        if (subtype === "lotcode") {
          this.setState({ lotConsecutive: lotConsecutive + 1 })
        }
      } else {
        if (position || position == 0) {
          newSubmission[c.id][position] = value;
        } else {
          newSubmission[c.id] = value;
        }
      }
      //Recipe Batch Size Logic
      if (isRecipe && c.defaultLabel == "yield") {
        //Old logic for Batch Size Logic
        newSubmission = this.calculateBatchSize(value, defaultYield, newSubmission, components);
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      // YesNoAll logic
      if (c.type === "yesnoall") {
        var section = components[id].section_id;
        components.forEach((c) => {
          if (c.type === "yesno" && c.section_id === section) {
            newSubmission[c.id] = value;
          }
        });
        // force yesno components to remount
        checkboxkey = checkboxkey + 1;
      }
      if (c.type === "lotcode" || c.type === "customlotcode") {
        this.setState({ lotConsecutive: lotConsecutive + 1 })
      }
    } else {
      if (subtype) {
        newSubmission[id] = {};
        if (position || position == 0) {
          newSubmission[id][position][subtype] = value;
        } else {
          newSubmission[id][position] = value;
        }
      } else {
        if (position || position == 0) {
          newSubmission[id][position] = value;
        } else {
          newSubmission[id] = value;
        }
      }
      // newSubmission[id] = value;
    }

    this.setState({ submission: newSubmission, checkboxkey: checkboxkey });
  }

  UNSAFE_componentWillReceiveProps(newProps) {

    const { companyTimezone, isRecipe } = this.props;
    var submission = {};
    var components = [];
    var choicelist = {};
    var uuid = "";

    var issues = newProps.submissionSelected?.issues
    if (issues) {
      issues = Object.values(issues)
      if (issues.length >= 0) {
        this.setState({ tmpIssues: issues }, this.forceUpdate.bind(this));
      }
    }
    // Initialize right after the form is loaded from network/memory
    if (!newProps.isLoaded || !this.props.isLoading) {
      return;
    }

    // Load old submission
    if (!this.props.newReport && !this.props.isSubmission) {
      uuid = this.props.previousSubmissions[this.props.task_id]["uuid"];
      submission = this.props.previousSubmissions[this.props.task_id][
        "submission"
      ];
      components = this.props.previousSubmissions[this.props.task_id][
        "components"
      ];
      choicelist = this.props.previousSubmissions[this.props.task_id][
        "choicelist"
      ];
    }

    if (!this.props.newReport && this.props.isSubmission) {
      uuid = this.props.submissionSelected.uuid;
      submission = this.props.submissionSelected.submission;
      const tmpComponents = this.props.submissionSelected.components;
      choicelist = this.props.submissionSelected.choicelist;
      let indextmpComponents = 0
      tmpComponents.forEach((comp, indx) => {
        let nComponent = { ...comp }
        if (comp.type === "section") {

          newProps.components.forEach(nComp => {
            if (nComp.label === comp.label) {
              if (nComp.attachmentId && typeof nComp.attachmentId !== 'undefined' && typeof nComp.attachmentId !== '' && typeof nComp.attachmentId !== null) {
                nComponent.attachment = nComp.attachment;
                nComponent.attachment_type = nComp.attachment_type
                nComponent.attachmentId = nComp.attachmentId

              }
            }

          });
        }
        components.push(nComponent)
      })
    }

    if ("components" in newProps && "choicelist" in newProps) {
      const date = moment().format("YYYY-MM-DD HH:mm");
      const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
      // New report
      if (this.props.newReport) {
        components = newProps.components;
        choicelist = newProps.choicelist;
        // components.filter(section => section.type == 'section').forEach((c) => {

        // })
        components.forEach((c) => {
          var tableSect = components.filter(sect => c.section_id == sect.section_id && sect.type == "section" && sect?.direction == "table" && c.type != "section");

          if (tableSect.length > 0) {
            var defaultData = 1;
            var dataType = tableSect[0]?.dataType ? tableSect[0]?.dataType : null;
            if (tableSect[0]?.defaultData > 0) {
              defaultData = tableSect[0]?.defaultData;
            } else {
              defaultData = 1;
            }
            defaultData = defaultData - 1;
            var defaultSubmission = tableSect[0]?.defaultSubmission ? tableSect[0]?.defaultSubmission : {};
            for (var comp = 0; comp <= defaultData; comp++) {
              if (comp == 0) {
                if (c.type === "datetime") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [initialDateTime];
                    }
                  } else {
                    submission[c.id] = [initialDateTime];
                  }
                } else if (
                  c.type === "inputlabel" ||
                  c.type === "input" ||
                  c.type === "textarea" ||
                  c.type === "lotecode" ||
                  c.type === "customlotcode" ||
                  c.type === "picture"
                ) {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [""];
                    }
                  } else {
                    submission[c.id] = [""];
                  }
                } else if (c.type === "dropdown") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [choicelist[c.object_type_id][0]["id"]];
                    }
                  } else {
                    submission[c.id] = [choicelist[c.object_type_id][0]["id"]];
                  }
                } else if (c.type === "signature"
                  || c.type === "timer"
                  || c.type === "timerauto") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [0];
                    }
                  } else {
                    submission[c.id] = [0];
                  }
                } else if (c.type == "yesno" || c.type == "yesnoall") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [0];
                    }
                  } else {
                    submission[c.id] = [0];
                  }
                }
                else if (c.type == "document") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [0];
                    }
                  } else {
                    submission[c.id] = [0];
                  }
                }
                else if (c.type == "chatAI") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [{ response: "", question: "" }];
                    }
                  } else {
                    submission[c.id] = [{ response: "", question: "" }];
                  }
                }
                else if (c.type == "inventory") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [{ lotcode: "", productname: "", quantity: "" }];
                    }
                  } else {
                    submission[c.id] = [{ lotcode: "", productname: "", quantity: "" }];
                  }
                }
              } else {
                var sub = submission[c.id];
                if (c.type === "datetime") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      // submission[c.id] = [defaultSubmission[c.id][comp]];
                      sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      sub.push(c.prefilledData);
                    } else {
                      sub.push(initialDateTime);
                    }
                  } else {
                    sub.push(initialDateTime);

                  }
                }
                else if (
                  c.type === "inputlabel" ||
                  c.type === "input" ||
                  c.type === "textarea" ||
                  c.type === "lotecode" ||
                  c.type === "customlotcode" ||
                  c.type === "picture"
                ) {

                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push("");
                    }
                  } else {
                    submission[c.id] = sub.push("");
                  }
                } else if (c.type === "dropdown") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(choicelist[c.object_type_id][0]["id"]);
                    }
                  } else {
                    submission[c.id] = sub.push(choicelist[c.object_type_id][0]["id"]);
                  }
                }
                else if (c.type === "signature"
                  || c.type === "timer"
                  || c.type === "timerauto") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(0);
                    }
                  } else {
                    submission[c.id] = sub.push(0);
                  }
                }
                else if (c.type == "yesno" || c.type == "yesnoall") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(0);
                    }
                  } else {
                    submission[c.id] = sub.push(0);
                  }
                } else if (c.type == "document") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(0);
                    }
                  } else {
                    submission[c.id] = sub.push(0);
                  }
                }
                else if (c.type == "chatAI") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push({ response: "", question: "" });
                    }
                  } else {
                    submission[c.id] = sub.push({ response: "", question: "" });
                  }
                }
                else if (c.type == "inventory") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push({ lotcode: "", productname: "", quantity: "" });
                    }
                  } else {
                    submission[c.id] = sub.push({ lotcode: "", productname: "", quantity: "" });
                  }
                }

                submission[c.id] = sub;
              }
            }
          } else {
            if (c.type === "datetime") submission[c.id] = initialDateTime;
            else if (
              c.type === "inputlabel" ||
              c.type === "input" ||
              c.type === "textarea" ||
              c.type === "lotecode" ||
              c.type === "customlotcode" ||
              c.type === "picture"
            ) {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = "";
              }
            } else if (c.type === "dropdown") {

              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = choicelist[c.object_type_id][0]["id"];
              }
            }
            else if (c.type === "signature"
              || c.type === "timer"
              || c.type === "timerauto") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = 0;
              }
            } else if (c.type == "yesno" || c.type == "yesnoall") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = 0;
              }
            } else if (c.type == "document") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = 0;
              }
            }
            else if (c.type == "chatAI") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = { response: "", question: "" };
              }
            }
            else if (c.type == "inventory") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = { lotcode: "", productname: "", quantity: "" };
              }
            }
          }
        });
      }
    }
    var productionComponent = components.find(component => component.defaultLabel == "production_date");
    if (productionComponent && components && submission && isRecipe && newReport) {
      var shelfLifeComponent = components.find(component => component.defaultLabel == "shelfLife");
      if (shelfLifeComponent) {
        var days = 0;
        var daysToMultiply = 1;
        var repeat_interval = shelfLifeComponent?.prefilledData?.repeat_interval ? parseFloat(shelfLifeComponent?.prefilledData?.repeat_interval) : "";
        var repeat_frequency = shelfLifeComponent?.prefilledData?.repeat_frequency ? shelfLifeComponent?.prefilledData?.repeat_frequency : "";
        if (repeat_frequency) {
          switch (repeat_frequency) {
            case "Weeks":
              daysToMultiply = 7;
              break;
            case "Months":
              daysToMultiply = 30;
              break;
          }
        }
        if (repeat_interval && repeat_interval != "NaN") {
          days = daysToMultiply * repeat_interval;
        }
        if (days > 0) {
          var expirationComponent = components.find(component => component.defaultLabel == "expiration_date");
          if (productionComponent && expirationComponent) {
            var productionDate = submission[productionComponent.id] ? moment(submission[productionComponent.id]).tz(companyTimezone) : "";
            var expirationDate = submission[expirationComponent.id] ? moment(submission[expirationComponent.id]).tz(companyTimezone) : "";

            if (productionDate) {
              expirationDate = productionDate.add(days, 'd');
              expirationDate = expirationDate.format("YYYY-MM-DDTHH:mm");
              submission[expirationComponent.id] = expirationDate;
            }
          }
        }
      }
    }

    this.setState({
      submission,
      components,
      choicelist,
      uuid,

    });
  }

  // Do not render when this.state.submissions is modified
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.props.isErrorSave !== nextProps.isErrorSave ||
      this.props.isErrorRetrieve !== nextProps.isErrorRetrieve ||
      this.props.isLoaded !== nextProps.isLoaded ||
      this.props.isSaving !== nextProps.isSaving ||
      this.state.form !== nextState.form ||
      this.state.lotConsecutive !== nextState.lotConsecutive ||
      this.state.checkboxkey !== nextState.checkboxkey ||
      this.state.sectionIndex !== nextState.sectionIndex ||
      this.state.errorMessage !== nextState.errorMessage ||
      this.state.active !== nextState.active ||
      this.state.rerenderTable != nextState.rerenderTable ||
      this.state.itemComponentChanged !== nextState.itemComponentChanged ||
      this.state.formLoaded !== nextState.formLoaded

    );
  }

  submitFormSave(status = "in_progress") {
    this.props.startSubmit(
      this.state.components,
      this.state.choicelist,
      this.state.submission,
      this.props.newReport ? uuid.v1() : this.state.uuid,
      this.props.taskSelected._id,
      this.props.isLogGroup ? this.props.logGroupSelected.id : '',
      status
    );
    // this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');

  }

  submitForm = async (status) => {
    const { submission, lastStatus } = this.state;
    let cont = 0;
    let labels = [];
    const result = this.state.components.filter(f => f.mandatory === true);

    for (let i = 0; i < result.length; i++) {
      let index = Object.keys(this.state.submission).indexOf(result[i].id);
      let valueMandatory = Object.values(this.state.submission)[index];

      if (valueMandatory != undefined) {
        if (valueMandatory.length > 0 || valueMandatory != 0) {

          if (result[i].type === 'inventory') {
            if (valueMandatory.lotcode && valueMandatory.productname && valueMandatory.quantity) {
              if (valueMandatory.lotcode.length > 0 && valueMandatory.productname.length > 0 && valueMandatory.quantity.length > 0) {
                cont++;
              } else {
                labels.push(result[i].label);
              }
            } else {
              labels.push(result[i].label);
            }

          } else {
            cont++;
          }
        } else {
          labels.push(result[i].label);
        }
      } else {
        labels.push(result[i].label);
      }
    }
    if (cont === result.length) {
      // await this.props.unsetStatusChange();
      this.props.startSubmit(
        this.state.components,
        this.state.choicelist,
        this.state.submission,
        this.props.newReport ? uuid.v1() : this.state.uuid,
        this.props.taskSelected._id,
        this.props.isLogGroup ? this.props.logGroupSelected.id : '',
        status
      );
      // this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');

    } else {
      await this.props.setStatusChange();
      submission.status = lastStatus;
      this.setState({ submission: submission })

      Swal.fire({
        title: "Please check your form",
        text: "Complete the required fields before submitting the report " + '(' + labels.toString().replaceAll(",", ", ") + ')',
        confirmButtonText: "Ok",
        confirmButtonColor: "#F4981E",
        backdrop: false
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }
  }


  navigateToGroup() {
    this.props.setLogGroupsSubAction()
  }

  renderIssue(issues, component) {
    let issueComment = "";
    let found = false;
    let issueRender = []
    if (Array.isArray(issues))
      issues?.map((issue) => {
        if (issue.id == component.id) {
          issueComment = issue.comment;
          found = true;
          issueRender = issue;
        }
      });

    return found ? issueRender : null;

  }

  printAllIssues(issues, component) {
    const { companyTimezone } = this.props;
    const locIssues = issues.filter(issue => issue?.id == component?.id);
    return (
      <View style={{ flex: 1 }}>

        {locIssues.map(issue => {
          // var cDate = new Date(issue.createdDate)
          // var sDate = new Date(issue.solvedDate)
          return <React.Fragment>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                {issue.solved ?
                  <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                  <Text style={styles.issueText}>{issue?.comment}</Text>}
              </TouchableOpacity>
              {!issue.solved &&
                <TouchableOpacity style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                  onPress={() => {
                    const { submissionSelected } = this.props;
                    const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                    const newIssues = [...issues];
                    newIssues[commentIndx] = {
                      ...issue,
                      solved: true
                    }
                    this.props.loadingIssue(component.id);
                    this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => this.props.loadingIssue(null));
                  }}
                >
                  <Text style={styles.issueText}>Solve</Text>
                </TouchableOpacity>
              }
            </View>
            {
              issue.createdBy && issue.createdDate ?
                <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {this.convertUTCDateToLocalDate(cDate).toLocaleString('en-US')}</Text>

                : null
            }
            {
              issue.solvedBy && issue.solvedDate ?
                <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {this.convertUTCDateToLocalDate(sDate).toLocaleString('en-US')}</Text>

                : null
            }
            <View>

            </View>
          </React.Fragment>
        }
        )
        }

      </View>
    )
  }

  showSave() {

    const { submissionSelected, formId, taskSelected, isLogGroup } = this.props;
    const { formLoaded } = this.state;
    const status = submissionSelected ? submissionSelected.status : "new";

    if (status != 'archived' && status != 'approved' && status != 'complete')
      return (
        <View style={styles.submitContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              if (this.props.payment_completed) {
                //Validate Status
                var id = taskSelected ? taskSelected?._id : null;
                if (status == "new" && isLogGroup) {
                  this.props.checkLogStatus(id, (statusBD) => {
                    if (!statusBD || statusBD == "pending" || statusBD == "edit_required") {
                      if (formLoaded) this.submitFormSave('in_progress');
                    } else {
                      this.props.showLogStatusError(statusBD);
                    }
                  })

                } else {
                  if (!id) {
                    id = this.props.newReport ? uuid.v1() : this.state.uuid;
                  }
                  this.props.checkRecordStatus(id, (statusBD) => {
                    if (!statusBD || statusBD == "in_progress" || statusBD == "edit_required") {
                      if (formLoaded) this.submitFormSave('in_progress');
                    } else {
                      this.props.showStatusError(statusBD);
                    }
                  })
                }

              } else {
                this.setState({ showPaymentInfo: true, callOnSave: true }, () => { this.forceUpdate() });
              }
            }}
          >
            <Text style={styles.buttonText}>
              Save
            </Text>
          </TouchableOpacity>
        </View>
      );
    else if (status != 'complete')
      return (
        <TouchableOpacity style={styles.readOnlyContainer}>
          <Text style={{ alignSelf: "center" }}>This record is read only</Text>
        </TouchableOpacity>
      );


  }

  saveIssue(issue, issues, submissionId,) {
    const newIssues = Array.isArray(issues) ? [...issues, issue] : [issue];
    this.props.sendSubmit(submissionId, newIssues, issue.comment);
  }

  convertUTCDateToLocalDate(date) {

    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString('en-US');
  }
  nextSection() {
    const { sectionIndex, components } = this.state;
    const compCount = components.filter(section => section.type == 'section' && !section?.hide);
    if (sectionIndex != (compCount.length - 1)) {
      this.setState({ sectionIndex: sectionIndex + 1 });
    }
  }
  previousSection() {
    const { sectionIndex } = this.state;
    if (sectionIndex != 0) {
      this.setState({ sectionIndex: sectionIndex - 1 });
    }
  }
  onPressSection(val) {
    this.setState({ sectionIndex: val });
  }
  getLabels() {
    const { components } = this.state;
    var array = [];
    components.filter(section => section.type == 'section' && !section?.hide).map((section, id) => {
      array.push(section.label);
    });

    return array;
  }

  getLabelsLength() {
    const { components } = this.state;
    var array = [];
    components.filter(section => section.type == 'section' && !section?.hide).map((section, id) => {
      array.push('');
    });

    return array.length;
  }

  sectionTitle() {
    const { sectionIndex, components } = this.state;
    const compCount = components.filter(section => section.type == 'section' && !section?.hide);
    if (compCount.length > 0) {
      return compCount[sectionIndex].label;
    }
  }
  getTableHeaders(components, section_id) {
    const { isInvoice, isShipmentLog } = this.props;
    var labels = [];
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      var label = component.mandatory && component.type != 'datetime' && component.type != 'datetime' ? component?.label + " *" : component?.label;
      if (!isInvoice && !isShipmentLog) {
        if (!component?.hide) {
          labels.push(label);
        }
      } else {
        if (component?.defaultLabel != "item_invoice_id" && component?.defaultLabel != "ingredient_id" && component?.defaultLabel != "item_invoice_id_wip") {
          if (!component?.hide) {
            labels.push(label);
          }
        }
      }
    });
    return labels;
  }
  getRowComponents(components, section_id, position) {
    var filteredComponents = [];
    const { isLoadingIssue, submissionSelected, companyTimezone, formSubmission, isPallet, userRole, isRecipe, isInvoice, isShipmentLog, inventoryTypes, isReceivingLog, isInventoryTransfer, items, packagingMaterials } = this.props;
    const { choicelist, checkboxkey, lotConsecutive, submission, resetInventorySearch } = this.state;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    const status = submissionSelected ? submissionSelected.status : "new";
    let issues = submissionSelected ? submissionSelected.issues : null;
    var tableSect = components.filter(sect => section_id == sect.section_id && sect.type == "section" && sect?.direction == "table");
    var defaultSubmission = {};
    var defaultData = null;
    var wipPositions = [];
    var sectionDefaulLabel = "";
    var section_id = "";

    if (tableSect.length > 0) {
      defaultSubmission = tableSect[0]?.defaultSubmission ? tableSect[0]?.defaultSubmission : {};
      wipPositions = tableSect[0]?.wipPositions ? tableSect[0]?.wipPositions : [];
      if (tableSect[0]?.defaultData) {
        defaultData = tableSect[0]?.defaultData - 1;
      } else {
        defaultData = 0;
      }
      if (tableSect[0]?.defaultLabel) {
        sectionDefaulLabel = tableSect[0]?.defaultLabel;
      }
      if (tableSect[0]?.section_id) {
        section_id = tableSect[0]?.section_id;
      }
    }
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      var initial;
      var disabled = false;
      let new_label;
      var showComponent = true;
      var itemInvoice_id = null;
      var showWipSearch = "";
      var options = [];
      var inventoryLocations = this.state.inventoryLocations;
      switch (component.type) {
        case "customlotcode":
          new_label = `${component.label} - ${component.lotcodetypename}`;
          break;
        case "inventory":
          new_label = `${component.label} - ${component.inventorytypename} Code`;
          break;
        default:
          new_label = `${component.label}`;
      }
      if (component?.dataType) {
        switch (component?.dataType) {
          case "default_data":
            if (position <= defaultData) {
              disabled = true;
            }
            if (position >= defaultData) {
              if ((isInvoice || isShipmentLog) && (component?.defaultLabel == "description" ||
                component?.defaultLabel == "quantity" ||
                component?.defaultLabel == "rate" ||
                component?.defaultLabel == "amount" ||
                component?.defaultLabel == "tax" ||
                component?.defaultLabel == "class")) {
                disabled = true;
              }
            }
            break;
          default:
            if (isPallet && component?.defaultLabel == "status") {
              if (userRole == "HACCP Coordinator" || userRole == "QA Manager" || userRole == "Person In Charge") {
                disabled = false;
              } else {
                disabled = true;
              }
            } else {
              disabled = false;
            }
            break;
        }
      } else {
        if (isPallet && component?.defaultLabel == "status") {
          if (userRole == "HACCP Coordinator" || userRole == "QA Manager" || userRole == "Person In Charge") {
            disabled = false;
          } else {
            disabled = true;
          }
        } else {
          disabled = false;
        }
      }
      if (position || position == 0) {
        if (submission[component.id]) {
          if (typeof submission[component.id] == "object" && submission[component.id].length > 0) {
            if (submission[component.id][position] !== undefined) {
              initial = submission[component.id][position]
            } else {
              initial = "";
            }
          } else {
            initial = submission[component.id]
          }
        } else {
          initial = "";
        }
      } else {
        initial = "";
      }
      if (isInvoice || isShipmentLog) {
        itemInvoice_id = components.filter(component => component.section_id == section_id && component.type != 'section').filter((c, idx) => c?.defaultLabel == "lotcode" || c?.defaultLabel == "item_invoice_id_wip");

        if (itemInvoice_id.length > 0) {
          itemInvoice_id = itemInvoice_id[0].id;
        }
      }
      if (component?.hide) {
        showComponent = false;
      }
      if ((isInvoice || isShipmentLog) && (component?.defaultLabel == "item_invoice_id" || component?.defaultLabel == "ingredient_id" || component?.defaultLabel == "item_invoice_id_wip")) {
        showComponent = false;
      }
      if ((isShipmentLog) && component?.defaultLabel == "ingredient_id") {
        showComponent = false;
      }
      if (isRecipe && component?.type == "customlotcode" && wipPositions?.length > 0) {
        wipPositions.map((wipPos) => {
          if (wipPos == position) {
            var ingredientComponent = components.filter(component => component.section_id == section_id && component.type != 'section').filter((c, idx) => c?.defaultLabel == "ingredient");
            if (ingredientComponent?.length > 0) {
              var ingredientComponentId = ingredientComponent[0].id;
              if (submission[ingredientComponentId]) {
                if (submission[ingredientComponentId][position]) {
                  showWipSearch = submission[ingredientComponentId][position];
                }
              }
            }
          }
        })
      }

      if (showComponent) {
        if (isInventoryTransfer && component?.defaultLabel == "fromLocation") {
          var lotComponent = components.find(component => component.section_id == section_id && component.type != 'section' && component?.defaultLabel == "lotcode");
          if (lotComponent) {
            var lotSelected = [];
            if (position || position == 0) {
              lotSelected = submission[lotComponent.id][position];
            } else {
              lotSelected = submission[lotComponent.id];
            }
            if (lotSelected?.length > 0 && typeof lotSelected == "object") {
              var filteredInventoryLocations = [];
              lotSelected.map((lot) => {
                var gotTransfers = false;
                if (lot != "default_lot") {
                  if (lot?.historial) {
                    lot.historial.map((h) => {
                      if (h?.location) {
                        var location = h?.location;
                        if (location != "default_location") gotTransfers = true;
                        var fromLocationValue = this.state.inventoryLocations.filter((l) => {
                          var found = true;
                          if (location == "default_location") {
                            if (!l?.site?.defaultLocation) {
                              found = false;
                            }
                          }
                          if (location?.site?._id) {
                            if (l?.site?._id != location?.site?._id) found = false;
                          }
                          if (location?.zone?._id) {
                            if (l?.zone?._id != location?.zone?._id) found = false;
                          }
                          if (location?.aisle?._id) {
                            if (l?.aisle?._id != location?.aisle?._id) found = false;
                          }
                          if (location?.rackBay?._id) {
                            if (l?.rackBay?._id != location?.rackBay?._id) found = false;
                          }
                          if (location?.shelfLevel?._id) {
                            if (l?.shelfLevel?._id != location?.shelfLevel?._id) found = false;
                          }
                          if (location?.bin?._id) {
                            if (l?.bin?._id != location?.bin?._id) found = false;
                          }

                          return found;
                        });
                        fromLocationValue.map((locationValue) => {
                          var foundLocation = filteredInventoryLocations.find((l) => {
                            var found = true;
                            if (locationValue == "default_location") {
                              if (!l?.site?.defaultLocation) {
                                found = false;
                              }
                            }
                            if (locationValue?.site?._id) {
                              if (l?.site?._id != locationValue?.site?._id) found = false;
                            }
                            if (locationValue?.zone?._id) {
                              if (l?.zone?._id != locationValue?.zone?._id) found = false;
                            }
                            if (locationValue?.aisle?._id) {
                              if (l?.aisle?._id != locationValue?.aisle?._id) found = false;
                            }
                            if (locationValue?.rackBay?._id) {
                              if (l?.rackBay?._id != locationValue?.rackBay?._id) found = false;
                            }
                            if (locationValue?.shelfLevel?._id) {
                              if (l?.shelfLevel?._id != locationValue?.shelfLevel?._id) found = false;
                            }
                            if (locationValue?.bin?._id) {
                              if (l?.bin?._id != locationValue?.bin?._id) found = false;
                            }

                            return found;
                          });

                          if (!foundLocation) {
                            filteredInventoryLocations.push(locationValue);
                            inventoryLocations = filteredInventoryLocations;
                          }
                        });
                      }
                    })
                    if (!gotTransfers) {
                      var defaultLocation = this.state.inventoryLocations.filter((l) => {
                        var found = false;
                        if (l?.site?.defaultLocation) {
                          found = true;
                        }

                        return found;
                      });

                      inventoryLocations = defaultLocation;
                    }
                  }
                } else {
                  var itemComponent = components.find(component => component.section_id == section_id && component.type != 'section' && (component?.defaultLabel == "itemName" || component?.defaultLabel == "item"));
                  if (itemComponent) {
                    var name = submission[itemComponent.id][position] ? submission[itemComponent.id][position] : "";
                    var item = null;
                    var lots = [];
                    if (sectionDefaulLabel == "rawMaterials") {
                      item = items.find((i) => i?.itemName == name);
                    }
                    if (sectionDefaulLabel == "finishedGoods") {
                      item = inventoryTypes.find((i) => i?.item == name);
                    }
                    if (sectionDefaulLabel == "packagingMaterials") {
                      item = packagingMaterials.find((i) => i?.namePackaging == name);
                    }
                    if (sectionDefaulLabel == "workInProgress") {
                      item = inventoryTypes.find((i) => i?.item == name);
                    }

                    if (item) {
                      if (item?.items) {
                        lots = item.items;
                      }
                      if (lots?.length > 0) {
                        lots.map((lot) => {
                          if (lot?.historial?.length > 0) {
                            lot.historial.map((h) => {
                              if (h?.location) {
                                var location = h?.location;
                                if (location != "default_location") gotTransfers = true;
                                var fromLocationValue = this.state.inventoryLocations.filter((l) => {
                                  var found = true;
                                  if (location == "default_location") {
                                    if (!l?.site?.defaultLocation) {
                                      found = false;
                                    }
                                  }
                                  if (location?.site?._id) {
                                    if (l?.site?._id != location?.site?._id) found = false;
                                  }
                                  if (location?.zone?._id) {
                                    if (l?.zone?._id != location?.zone?._id) found = false;
                                  }
                                  if (location?.aisle?._id) {
                                    if (l?.aisle?._id != location?.aisle?._id) found = false;
                                  }
                                  if (location?.rackBay?._id) {
                                    if (l?.rackBay?._id != location?.rackBay?._id) found = false;
                                  }
                                  if (location?.shelfLevel?._id) {
                                    if (l?.shelfLevel?._id != location?.shelfLevel?._id) found = false;
                                  }
                                  if (location?.bin?._id) {
                                    if (l?.bin?._id != location?.bin?._id) found = false;
                                  }

                                  return found;
                                });
                                fromLocationValue.map((locationValue) => {
                                  var foundLocation = filteredInventoryLocations.find((l) => {
                                    var found = true;
                                    if (locationValue == "default_location") {
                                      if (!l?.site?.defaultLocation) {
                                        found = false;
                                      }
                                    }
                                    if (locationValue?.site?._id) {
                                      if (l?.site?._id != locationValue?.site?._id) found = false;
                                    }
                                    if (locationValue?.zone?._id) {
                                      if (l?.zone?._id != locationValue?.zone?._id) found = false;
                                    }
                                    if (locationValue?.aisle?._id) {
                                      if (l?.aisle?._id != locationValue?.aisle?._id) found = false;
                                    }
                                    if (locationValue?.rackBay?._id) {
                                      if (l?.rackBay?._id != locationValue?.rackBay?._id) found = false;
                                    }
                                    if (locationValue?.shelfLevel?._id) {
                                      if (l?.shelfLevel?._id != locationValue?.shelfLevel?._id) found = false;
                                    }
                                    if (locationValue?.bin?._id) {
                                      if (l?.bin?._id != locationValue?.bin?._id) found = false;
                                    }

                                    return found;
                                  });

                                  if (!foundLocation) {
                                    filteredInventoryLocations.push(locationValue);
                                    inventoryLocations = filteredInventoryLocations;
                                  }
                                });
                              }
                            })
                          }
                        });
                        if (!gotTransfers) {
                          var defaultLocation = this.state.inventoryLocations.filter((l) => {
                            var found = false;
                            if (l?.site?.defaultLocation) {
                              found = true;
                            }

                            return found;
                          });

                          inventoryLocations = defaultLocation;
                        }
                      }
                    }
                  }
                }

              })
            }
            var defaultLocationIdx = inventoryLocations.findIndex((loc) => loc?.site?.defaultLocation == true);
            if (defaultLocationIdx > 0) {
              var dL = inventoryLocations.splice(defaultLocationIdx, 1);
              if (dL) {
                inventoryLocations.splice(0, 0, dL[0]);
              }
            }
          }
        }

        filteredComponents.push(
          <>
            {
              isLoadingIssue != null && component.id == isLoadingIssue ?
                <ActivityIndicator size={150} color="rgb(0, 47, 108)"></ActivityIndicator>
                :
                <View>
                  <FormComponent
                    id={component.id}
                    type={component.type}
                    key={component.id}
                    label={component.type == "inventory" ? new_label : ''}
                    placeholder={component.placeholder ? component.placeholder : ""}
                    componentLink={component.componentLink ? component.componentLink : ""}
                    enabledLink={component.enabledLink ? component.enabledLink : ""}
                    initial={initial}
                    onChange={(id, value, subtype, position) => {
                      this.onComponentChange(id, value, subtype, position)
                    }}
                    options={choicelist[component.object_type_id]}
                    checkboxkey={checkboxkey}
                    status={submissionSelected ? submissionSelected.status : false}
                    submissionSelected={submissionSelected ? submissionSelected : null}
                    showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                    lotConsecutive={lotConsecutive}
                    customlot={false}
                    isIssuable={submissionId != null}
                    onSubmitIssue={(issue) => {
                      this.props.loadingIssue(component.id);
                      this.saveIssue({ ...issue, position: position }, issues, submissionId)
                      setTimeout(() => {
                        this.props.loadingIssue(null);
                      }, 1000)
                    }}
                    openIssue={() => {
                      this.setState({
                        openTableIssue: true,
                        compIssueId: component.id,
                        issueLabel: '',
                        issuePosition: position,
                        issueWidth: component?.width ? component?.width : "small",
                        rerenderTable: !this.state.rerenderTable
                      });
                    }}
                    companyTimezone={companyTimezone}
                    position={position}
                    width={component?.width ? component?.width : "small"}
                    defaultComponent={(status == 'archived' || status == 'approved') || formSubmission ? true : disabled}
                    closeIssue={() => {
                      this.setState({ rerenderTable: !this.state.rerenderTable });
                    }}
                    showInventorySearch={component?.showInventorySearch || (isRecipe && component?.defaultLabel == "lotcode" && (sectionDefaulLabel == "ingredients" || sectionDefaulLabel == "packagingMaterials")) || (isInvoice && component?.defaultLabel == "lotcode")}
                    showFinishedGoodsSearch={component?.showFinishedGoodsSearch}
                    showWorkInProgressSearch={component?.showWorkInProgressSearch}
                    showItemsSearch={component?.showItemsSearch || (isRecipe && (component?.defaultLabel == "ingredient") && (sectionDefaulLabel == "ingredients"))}
                    showPackagingSearch={component?.showPackagingSearch || (isRecipe && (component?.defaultLabel == "name") && (sectionDefaulLabel == "packagingMaterials"))}
                    defaultLabel={component?.defaultLabel}
                    isRecipe={isRecipe}
                    isInvoice={isInvoice}
                    submission={submission}
                    itemInvoice_id={itemInvoice_id}
                    isShipmentLog={isShipmentLog}
                    resetInventorySearch={resetInventorySearch}
                    showWipSearch={showWipSearch}
                    components={components}
                    inventoryTypes={inventoryTypes}
                    searchClients={component?.searchClients}
                    searchSuppliers={isReceivingLog && component?.defaultLabel == "approvedSupplier"}
                    section_id={section_id}
                    isInventoryTransfer={isInventoryTransfer}
                    inventoryLocations={inventoryLocations}
                  />
                  {issues && this.renderIssue(issues, component) != null ? <View style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 10,

                  }}>
                    {this.printAllIssues(issues, component, position, true)}
                  </View> : null}
                </View>
            }

          </>
        )
      }
    })

    return filteredComponents;
  }

  renderRows(rows, components, section_id) {
    var renderedRows = [];
    for (let i = 0; i <= rows; i++) {
      renderedRows.push(<Row data={this.getRowComponents(components, section_id, i)} widthArr={this.getCellArrayWidth(components, section_id)} style={i % 2 == 0 ? { backgroundColor: '#EDEEEE', justifyContent: "flex-start", zIndex: 'unset' } : { backgroundColor: 'white', justifyContent: "flex-start" }} textStyle={styles.text} />);
    }

    return renderedRows;
  }

  getCellArrayWidth(components, section_id) {
    const { isInvoice, isShipmentLog } = this.props;
    var widthArray = [];
    var w = 150;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      var showComponent = true;
      if ((isInvoice || isShipmentLog) && (component?.defaultLabel == "item_invoice_id" || component?.defaultLabel == "ingredient_id" || component?.defaultLabel == "item_invoice_id_wip")) {
        showComponent = false;
      }
      if (component?.hide) {
        showComponent = false;
      }

      if (showComponent) {
        switch (component?.width) {
          case 'mini':
            w = 134;
            break;
          case 'small':
            w = 200;
            break;
          case 'medium':
            w = 300;
            break;
          case 'large':
            w = 480;
            break;
          default:
            w = 200;
            break;
        }
        widthArray.push(w);
      }
    });
    return widthArray;
  }

  checkForRows(components, section_id) {
    const { submission } = this.state;
    var rowNumber = null;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      if (component?.defaultLabel != "item_invoice_id_wip") {
        var sub = submission[component.id];
        if (typeof sub === 'object' && sub?.length) {
          rowNumber = sub.length;
        }
      }
    })
    if (!rowNumber) {
      rowNumber = 1;
    }
    return rowNumber - 1;
  }
  restTableRow(section_id, firstRow) {
    const { companyTimezone } = this.props;
    const { submission, components, resetInventorySearch } = this.state;
    var newSubmission = JSON.parse(JSON.stringify(submission));
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");

    components.filter(component => component.type != 'section' && component.section_id == section_id).map((component) => {
      if (submission[component.id]) {
        if (typeof submission[component.id] == "object" && submission[component.id].length > 0) {
          if (firstRow) {
            switch (component.type) {
              case "datetime":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSub[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = initialDateTime;
                  }
                }
                break;
              case "dropdown":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = "";
                  }
                }
                break;
              case
                "signature",
                "timer",
                "timerauto",
                "document":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = 0;
                  }
                }
                break;
              case "yesno",
                "yesnoall":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = 0;
                  }
                }
                break;
              default:
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = "";
                  }
                }
                break;
            }
          } else {
            newSubmission[component.id].splice(submission[component.id].length - 1, 1);
          }
        }
      }
    });
    if (firstRow) {
      this.setState({ submission: newSubmission, resetInventorySearch: { changed: !resetInventorySearch.changed, position: 0 } });
    }
    this.setState({ submission: newSubmission, rerenderTable: !this.state.rerenderTable });

  }

  addTableRow(section_id, choicelist) {
    const { companyTimezone } = this.props;
    const { submission, components } = this.state;
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
    var newSubmission = JSON.parse(JSON.stringify(submission));
    components.filter(component => component.type != 'section' && component.section_id == section_id).map((component) => {
      var sub = submission[component.id];
      if (typeof sub === 'object' && sub.length > 0) {
        switch (component.type) {
          case "datetime":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(initialDateTime);
            }
            break;
          case "dropdown":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(choicelist[component.object_type_id][0]["id"]);
            }
            break;
          case
            "signature",
            "timer",
            "timerauto",
            "document":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(0);
            }
            break;
          case "yesno",
            "yesnoall":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(0);
            }
            break;
          default:
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push("");
            }
            break;
        }
      } else {
        var newSub = [sub];
        switch (component.type) {
          case "datetime":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(initialDateTime);
            }
            break;
          case "dropdown":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(choicelist[component.object_type_id][0]["id"]);
            }
            break;
          case
            "signature",
            "timer",
            "timerauto",
            "document":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(0);
            }
            break;
          case "yesno",
            "yesnoall":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(0);
            }
            break;
          default:
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push("");
            }
            break;
        }
        newSubmission[component.id] = newSub;
      }
    })
    this.setState({ submission: newSubmission, rerenderTable: !this.state.rerenderTable })
  }
  editTableRows(defaultData, section_id, defaultSubmission, dataType) {
    const { components, submission, choicelist } = this.state;
    const { companyTimezone } = this.props;
    var actualRows = this.checkForRows(components, section_id) + 1;
    var newSub = JSON.parse(JSON.stringify(submission));
    var newComponents = JSON.parse(JSON.stringify(components));
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
    newComponents.map((component) => {
      if (component.type == 'section' && component.section_id == section_id) {
        component.dataType = dataType;
      }
    })
    components.filter(section => section.type != 'section' && section.section_id == section_id).map((component) => {
      var sub = newSub[component.id];

      if (Object.entries(defaultSubmission).length > 0) {
        if (defaultSubmission[component.id]) {
          defaultSubmission[component.id].map((defaultSub, defaultIndex) => {
            if (defaultSub.changed) {
              sub[defaultIndex] = defaultSub.value;
            }
          })
        }
      }

      if (actualRows > defaultData) {
        var dif = actualRows - defaultData;
        for (let i = 0; i <= (dif - 1); i++) {
          sub.splice(sub.length - 1, 1)
        }
      } else if (defaultData > actualRows) {
        var dif = defaultData - actualRows;
        for (let i = 0; i <= (dif - 1); i++) {

          switch (component.type) {
            case "datetime":
              sub.push(initialDateTime);
              break;
            case "dropdown":
              sub.push(choicelist[component.object_type_id][0]["id"]);
              break;
            case
              "signature",
              "timer",
              "timerauto",
              "document":
              sub.push(0);
              break;
            case "yesno",
              "yesnoall":
              sub.push(0);
              break;
            default:
              sub.push("");
              break;
          }
        }
      }
      newSub[component.id] = sub;
    })
    this.setState({ submission: newSub, components: newComponents, rerenderTable: !this.state.rerenderTable });
  }
  calculateBatchSize(v, d, submission, components) {
    var newSubmission = submission;

    var yield_section = components.filter((c) => c.defaultLabel == "moreRecipeInformation");
    var ingredients_section = components.filter((c) => c.defaultLabel == "ingredients");
    var packaging_materials_section = components.filter((c) => c.defaultLabel == "packagingMaterials");
    var value = parseFloat(v);
    var defaultYield = d ? parseFloat(d) : 1;
    value = value;
    defaultYield = defaultYield ? defaultYield : 1;

    //Check for yield section
    if (yield_section.length > 0) {
      var yield_id = components.filter(c => c.section_id == yield_section[0].section_id && c?.defaultLabel == "amount");
      if (yield_id.length > 0) {
        newSubmission[yield_id[0].id][0] = v;
      }
    }
    //Check for ingredients
    if (ingredients_section.length > 0) {
      var ingredients_id = components.filter(c => c.section_id == ingredients_section[0].section_id && c.label == "Quantity");
      if (ingredients_id.length > 0) {
        var id = ingredients_id[0].id;
        var qty_sub = submission[id];
        qty_sub.map((q, idx) => {
          var val = parseFloat(q);
          val = val;
          if (val && value) {
            var defaultSubmission = ingredients_section[0]?.defaultSubmission ? ingredients_section[0]?.defaultSubmission[id] : null;
            if (defaultSubmission) {
              defaultSubmission = parseFloat(defaultSubmission[idx]);
              if (defaultSubmission) {
                var new_value = (defaultSubmission / defaultYield) * value;
                newSubmission[id][idx] = new_value.toFixed(2);
              }
            } else {
              var new_value = (val / defaultYield) * value;
              newSubmission[id][idx] = new_value.toFixed(2);
            }
          }
        });
      }
    }

    //Check for packaging materials
    if (packaging_materials_section.length > 0) {
      var packaging_id = components.filter(c => c.section_id == packaging_materials_section[0].section_id && c.label == "Quantity");
      if (packaging_id.length > 0) {
        var id = packaging_id[0].id;
        var qty_sub = submission[id];
        qty_sub.map((q, idx) => {
          var val = parseFloat(q);
          val = val;
          if (val && value) {
            var defaultSubmission = packaging_materials_section[0]?.defaultSubmission ? packaging_materials_section[0]?.defaultSubmission[id] : null;
            if (defaultSubmission) {
              defaultSubmission = parseFloat(defaultSubmission[idx]);
              if (defaultSubmission) {
                var new_value = (defaultSubmission / defaultYield) * value;
                newSubmission[id][idx] = new_value.toFixed(2);
              }
            } else {
              var new_value = (val / defaultYield) * value;
              newSubmission[id][idx] = new_value.toFixed(2);
            }
          }
        });
      }
    }
    return newSubmission;
  }
  render() {
    const { submission, choicelist, checkboxkey, showPaymentInfo, callOnSave, components, lotConsecutive, formLoaded, sectionIndex, errorMessage, form, rerenderTable, palletComponentChanged, itemComponentChanged, resetInventorySearch, inventoryTypes, inventoryLocations } = this.state;
    const { submissionSelected, formSubmission, isLoadingIssue, companyTimezone, formName, company_logo, promptMessages, isRecipe, isInvoice, isShipmentLog, isReceivingLog, isInventoryTransfer } = this.props;
    let issues = submissionSelected ? submissionSelected.issues : null;
    if (this.state.tmpIssues.length > 0)
      issues = this.state.tmpIssues;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    console.log(submission);
    console.log(isRecipe);

    if (errorMessage != "") {
      return <View style={{ flexDirection: "row", }}>
        <Text style={styles.labelFirst2}>{errorMessage}</Text>
      </View>
    }
    if (promptMessages && form) {
      return <PromptFormView
        promptMessages={promptMessages}
        form={form}
        formLoaded={formLoaded}
        formName={formName}
        submitForm={(status) => status ? this.submitForm(status) : this.submitForm()}
        submitFormSave={(status) => status ? this.submitFormSave(status) : this.submitFormSave()}
        submissionSelected={submissionSelected} />
    }
    return (
      <View style={{ flexDirection: "row", }}>
        <PaymentInfo visible={showPaymentInfo} onSave={this.submitFormSave.bind(this)} callOnSave={callOnSave} />
        <View style={{ flex: 2, }} />
        <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
          {
            !this.props.isLoading &&
            <View style={{
              display: 'flex', flexDirection: 'row', marginTop: 20
            }}>
              <Text style={styles.labelFirst3}>{formName}</Text>
            </View>
          }
          {
            !this.props.isLoading &&
            <StepIndicator
              customStyles={customStyles}
              currentPosition={sectionIndex}
              labels={this.getLabels(components)}
              stepCount={this.getLabelsLength(components)}
              onPress={(event) => this.onPressSection(event)}
            />
          }
          {
            !this.props.isLoading &&
            <View style={{
              display: 'flex', flexDirection: 'row', marginTop: 20
            }}>
              <View style={{ flex: 1 }} >
                {
                  sectionIndex != 0 &&
                  <TouchableOpacity onPress={() => this.previousSection()}>
                    <Icon name="arrow-left" style={styles.iconStyle} />
                  </TouchableOpacity>
                }

              </View>
              <View style={{ flex: 10, textAlign: 'center' }}>
                <Text style={styles.labelFirst}>{this.sectionTitle()}</Text>
              </View>
              <View style={{ flex: 1 }}>
                {
                  sectionIndex != (components.filter(section => section.type == 'section' && !section?.hide).length - 1) &&
                  <TouchableOpacity onPress={() => this.nextSection()}>
                    <Icon name="arrow-right" style={styles.iconStyle} />
                  </TouchableOpacity>
                }
              </View>
            </View>
          }
          {
            this.props.isLoaded || (this.props.isSaving && this.state.autosave) ? (

              <ScrollView
                contentContainerStyle={styles.innerView}
              >


                {components.filter(section => (section.type == 'section' && !section.hide)).map((section, id) => {
                  if (sectionIndex == id) {
                    if (section.direction && section.direction == 'table') {
                      return <Section
                        showSeparator={id === 0}
                        isLast={id == components.filter(section => section.type == 'section').length - 1}
                        label={section.label}
                        onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                        id={section.id}
                        media={section.attachment}
                        formSubmission={formSubmission}
                        attachment_type={section.attachment_type}
                        mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                        formId={this.props.formId}
                        setBigFileIsLoading={this.props.setBigFileIsLoading}
                        direction={section.direction}
                        defaultData={section?.defaultData ? section?.defaultData : 1}
                        section={section}
                        components={components}
                        choicelist={choicelist}
                        checkboxkey={checkboxkey}
                        onSaveDefault={(defaultData, defaultSubmission, dataType) => {
                          this.editTableRows(defaultData, section?.section_id, defaultSubmission, dataType);
                        }}
                        defaultSubmission={section?.defaultSubmission ? section?.defaultSubmission : {}}
                        rerenderTable={rerenderTable}
                        status={status}
                        palletComponentChanged={palletComponentChanged}
                        itemComponentChanged={itemComponentChanged}
                      >
                        <View>
                          <ScrollView horizontal={true}>
                            <View style={{ marginTop: 10, zIndex: 'unset' }}>
                              <Table style={{ justifyContent: "flex-start", zIndex: 'unset' }} borderStyle={{ borderWidth: 2, borderColor: '#fff', justifyContent: "flex-start", zIndex: 'unset' }}>
                                <Row data={this.getTableHeaders(components, section.section_id)} widthArr={this.getCellArrayWidth(components, section.section_id)} style={styles.head} textStyle={{ ...styles.text, color: 'white' }} />
                                {
                                  // this.checkForRows(components, section.section_id) ?
                                  this.renderRows(this.checkForRows(components, section.section_id), components, section.section_id)
                                  // :
                                  // <Row data={this.getRowComponents(components, section.section_id, 0)} widthArr={this.getCellArrayWidth(components, section.section_id)} style={{ backgroundColor: '#EDEEEE', justifyContent: "flex-start", zIndex: 'unset' }} textStyle={styles.text} />
                                }


                              </Table>
                            </View>

                          </ScrollView>
                        </View>
                        {(status != 'archived' && status != 'approved'
                          // && section?.defaultLabel != "moreRecipeInformation"
                        ) ?
                          <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.btnAdd}
                              onPress={() => { this.addTableRow(section.section_id, choicelist) }}
                            >
                              <Text>
                                <Icon name="plus-circle-outline" style={{ fontSize: 30, color: colors.add }} />
                              </Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnAdd}
                              onPress={() => {
                                var rows = this.checkForRows(components, section.section_id);
                                this.restTableRow(section.section_id, rows ? false : true);
                              }}
                            >
                              <Text>
                                <Icon name="close-circle-outline" style={{ fontSize: 30, color: colors.quit }} />
                              </Text>
                            </TouchableOpacity>
                          </View> : null
                        }
                      </Section>
                    } else {
                      return <Section
                        showSeparator={id === 0}
                        isLast={id == components.filter(section => section.type == 'section').length - 1}
                        label={section.label}
                        onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                        id={section.id}
                        media={section.attachment}
                        formSubmission={formSubmission}
                        attachment_type={section.attachment_type}
                        mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                        formId={this.props.formId}
                        choicelist={choicelist}
                        setBigFileIsLoading={this.props.setBigFileIsLoading}
                        checkboxkey={checkboxkey}
                        defaultSubmission={{}}
                        rerenderTable={rerenderTable}
                        status={status}
                        palletComponentChanged={palletComponentChanged}
                        itemComponentChanged={itemComponentChanged}
                      >
                        {components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
                          let new_label;
                          var disabled = false;
                          switch (component.type) {
                            case "customlotcode":
                              new_label = `${component.label} - ${component.lotcodetypename}`;
                              break;
                            case "inventory":
                              new_label = `${component.label} - ${component.inventorytypename} Code`;
                              if (typeof submission[component.id] == "undefined")
                                submission[component.id] = { lotcode: "", productname: "", quantity: "" };
                              break;
                            default:
                              new_label = `${component.label}`;
                          }
                          if (component?.dataType) {
                            switch (component?.dataType) {
                              case "default_data":
                                if (component?.defaultLabel != "date&time")
                                  disabled = true;
                                break;
                              default:
                                disabled = false;
                                break;
                            }
                          }

                          return <>
                            {
                              isLoadingIssue != null && component.id == isLoadingIssue ?
                                <ActivityIndicator size={150} color="rgb(0, 47, 108)"></ActivityIndicator>
                                :
                                <View>
                                  {
                                    !component?.hide &&
                                    <FormComponent
                                      id={component.id}
                                      type={component.type}
                                      key={component.id}
                                      label={component.mandatory && component.type != 'datetime' && component.type != 'datetime' ? new_label + " *" : new_label}
                                      placeholder={component.placeholder ? component.placeholder : ""}
                                      componentLink={component.componentLink ? component.componentLink : ""}
                                      enabledLink={component.enabledLink ? component.enabledLink : ""}
                                      initial={submission[component.id]}
                                      onChange={(id, value, subtype) => {
                                        this.onComponentChange(id, value, subtype)

                                      }}
                                      options={choicelist[component.object_type_id]}
                                      checkboxkey={checkboxkey}
                                      status={submissionSelected ? submissionSelected.status : false}
                                      submissionSelected={submissionSelected ? submissionSelected : null}
                                      showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                                      lotConsecutive={lotConsecutive}
                                      customlot={false}
                                      isIssuable={submissionId != null}
                                      onSubmitIssue={(issue) => {
                                        this.props.loadingIssue(component.id);
                                        this.setState({ rerenderTable: !this.state.rerenderTable });
                                        this.saveIssue(issue, issues, submissionId, () => {
                                          setTimeout(() => {
                                            this.props.loadingIssue(null);
                                            this.setState({ rerenderTable: !this.state.rerenderTable });
                                          }, 1000);
                                        });
                                      }}
                                      companyTimezone={companyTimezone}
                                      defaultComponent={(status == 'archived' || status == 'approved') || formSubmission ? true : disabled}
                                      promptMessages={promptMessages}
                                      showInventorySearch={component?.showInventorySearch || (isRecipe && component?.defaultLabel == "lotcode" && (section?.defaultLabel == "ingredients" || section?.defaultLabel == "packagingMaterials"))}
                                      showFinishedGoodsSearch={component?.showFinishedGoodsSearch}
                                      showWorkInProgressSearch={component?.showWorkInProgressSearch}
                                      showItemsSearch={component?.showItemsSearch}
                                      showPackagingSearch={component?.showPackagingSearch}
                                      defaultLabel={component?.defaultLabel}
                                      isRecipe={isRecipe}
                                      isInvoice={isInvoice}
                                      submission={submission}
                                      isShipmentLog={isShipmentLog}
                                      resetInventorySearch={resetInventorySearch}
                                      components={components}
                                      inventoryTypes={inventoryTypes}
                                      searchClients={component?.searchClients}
                                      searchSuppliers={isReceivingLog && component?.defaultLabel == "approvedSupplier"}
                                      section_id={section?.section_id}
                                      isInventoryTransfer={isInventoryTransfer}
                                      inventoryLocations={inventoryLocations}
                                    />
                                  }

                                  {issues && this.renderIssue(issues, component) != null ? <View style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: 10,
                                    marginBottom: 10,

                                  }}>
                                    {this.printAllIssues(issues, component, null, false)}
                                  </View> : null}

                                </View>
                            }

                          </>



                        })}
                      </Section>
                    }
                  } else {
                    return null;
                  }
                })}
                {
                  !this.props.isLoading &&
                  <View style={{
                    display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20
                  }}>
                    <View style={{ flex: 1 }} >
                      {
                        sectionIndex != 0 &&
                        <TouchableOpacity onPress={() => this.previousSection()}>
                          <Icon name="arrow-left" style={styles.iconStyle} />
                        </TouchableOpacity>
                      }

                    </View>
                    <View style={{ flex: 10, textAlign: 'center' }}></View>
                    <View style={{ flex: 1 }}>
                      {
                        sectionIndex != (components.filter(section => section.type == 'section' && !section?.hide).length - 1) &&
                        <TouchableOpacity onPress={() => this.nextSection()}>
                          <Icon name="arrow-right" style={styles.iconStyle} />
                        </TouchableOpacity>
                      }
                    </View>
                  </View>
                }
                {
                  !formSubmission && (sectionIndex == components.filter(section => section.type == 'section' && !section?.hide).length - 1) ?

                    <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }}
                      formLoaded={formLoaded}
                      submitForm={(status) => status ? this.submitForm(status) : this.submitForm()}
                      submitFormSave={(status) => status ? this.submitFormSave(status) : this.submitFormSave()} record={this.props.submissionSelected} navigateTo={(page) => console.log('navigateTo')} />
                    : null
                }
                {/* {
                  !formSubmission ? 

                    <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }}
                      formLoaded={formLoaded}
                      submitForm={(status) => status ? this.submitForm(status) : this.submitForm()}
                      submitFormSave={(status) => status ? this.submitFormSave(status) : this.submitFormSave()} record={this.props.submissionSelected} navigateTo={(page) => this.props.navigation.navigate(page)} />
                    : null
                }
                {
                  !formSubmission ?
                    this.showSave()
                    :null
                } */}
                {
                  formSubmission ?
                    <TouchableOpacity style={styles.readOnlyContainer}>
                      <Text style={{ alignSelf: "center" }}>This record is read only, the form was deleted from the admin and this record can not be modified.</Text>
                    </TouchableOpacity> : null
                }



              </ScrollView>
            ) : this.props.isLoading ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            ) : this.props.isSaving && !this.state.autosave ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Saving...</Text>
              </View>
            ) : this.props.isLoadingBigFile ?
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Uploading...</Text>
              </View>
              : (
                <View style={styles.fixedContainer}>
                  <View style={styles.fixedContainerHT}>
                    <MaterialCommunityIcons
                      name="alert-circle"
                      size={32}
                      color="#d00"
                      style={styles.icon}
                    />
                    <Text style={styles.errorText}>{this.props.error}</Text>
                  </View>
                  <View style={styles.fixedContainerHB}>
                    <TouchableOpacity
                      onPress={() => {
                        this.props.retry(
                          this.props.isErrorSave,
                          this.props.components,
                          this.props.choicelist,
                          this.state.submission,
                          this.props.newReport ? uuid.v1() : this.state.uuid,
                          this.props.task_id
                        );
                      }}
                    >
                      <Text>Retry</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}

          <LogDialog />
        </View>
        <View style={{ flex: 2 }} />
      </View>
    );


  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
      isLoadingBigFile: state.forms.getIn(["form", "status"]) === "loading_big_file",
      isErrorSave: state.forms.getIn(["form", "status"]) === "errorSaving",
      isErrorRetrieve:
        state.forms.getIn(["form", "status"]) === "errorRetrieving",
      formId: state.forms.getIn(['form', 'id']),
      // form: state.forms.get('form'),
      isLoaded: state.forms.getIn(["form", "status"]) === "loaded",
      isSaving: state.forms.getIn(["form", "status"]) === "saving",
      error: state.forms.getIn(["form", "errorMessage"]),
      components: state.forms.getIn(["form", "components"]),
      promptMessages: state.forms.getIn(["form", "promptMessages"]),
      formName: state.forms.getIn(["form", "name"]),
      choicelist: state.forms.getIn(["form", "choicelist"]),
      formSubmission: state.forms.getIn(["form", "formSubmission"]),
      isRecipe: state.forms.getIn(["form", "isRecipe"]),
      previousSubmissions: state.submissions.get("previousSubmissions"),
      /*syncState: state.sync.get("state"),*/
      isLogGroup: state.loggroups.get("isLogGroup"),
      logGroupSelected: state.loggroups.get("logGroupSelected"),
      submissionSelected: state.submissions.get("submissionSelected"),
      isSubmission: state.submissions.get("isSubmission"),
      isLoadingIssue: state.submissions.get("loadingIssue"),
      payment_completed: state.user.get("payment_completed"),
      companyTimezone: state.user.get("companyTimezone"),
      taskSelected: state.tasks.get("taskSelected"),
      company_logo: state.user.get("company_logo")

    };
  },
  (dispatch, props) => {

    return {
      createNewTask: (task) => {
        return new Promise((resolve, reject) => {
          dispatch(setIsSubmission(false));
          dispatch(setSubmissionSelected(null));
          dispatch(createNewTask(task));
          resolve();
        });
      },
      getSalesGuide: (id, callback) => {
        dispatch(getSalesGuide(id, callback));
      },
      sendSubmit: (submissionId, submissions, comment, callback) => dispatch(sendSubmit(submissionId, submissions, comment, callback)),
      checkRecordStatus: (uuid, callback) => dispatch(checkRecordStatus(uuid, callback)),
      showStatusError: (status) => dispatch(showStatusError(status)),
      checkLogStatus: (task_id, callback) => dispatch(checkLogStatus(task_id, callback)),
      showLogStatusError: (status) => dispatch(showLogStatusError(status)),
      loadingIssue: (value) => dispatch(loadingIssue(value)),
      setBigFileIsLoading: (payload) => dispatch(setBigFileIsLoading(payload)),
      getForm: (idSubmission, callback) => {
        dispatch(getForm(props.formId, idSubmission, callback));
      },
      setStatusChange: () => { dispatch(setStatusChange()) },
      unsetStatusChange: () => { dispatch(unsetStatusChange()) },
      setLogGroupsSubAction: () => {
        dispatch(setLogGroupsSubAction());
      },
      retry: (
        isErrorSave,
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        status
      ) => {
        if (isErrorSave) {
          dispatch(
            beginSubmit(
              props.formId,
              props.title,
              components,
              choicelist,
              submission,
              uuid,
              task_id,
              false,
              status
            )
          );
        } else {
          dispatch(getForm(props.formId, props.submissionSelected?._id));
        }
      },
      startSubmit: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        logroup,
        status
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            false,
            logroup,
            status
          )
        );
      },
      autoSave: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        autosave
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            autosave
          )
        );
      },
    };
  }
)(Form);

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  issueContainer: {
    flex: 1,
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: 'pink',
  },
  readOnlyContainer: {
    flexGrow: 1,
    backgroundColor: "lightblue",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  container: {
    flex: 1,
    backgroundColor: "#B3B7BC",
    flexDirection: "column",
    justifyContent: "center",
  },
  keyboardAvoid: {
    flex: 1,
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  fixedContainer: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
  },
  fixedContainerHB: {
    marginTop: 20,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  innerView: {
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  menuContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20,
  },
  submitContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.gray_darken_2,
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    height: 60,
  },
  buttonText: {
    color: colors.primary,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  iconInProgress: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconEditRequired: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForReview: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForApproval: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightblue',
  },
  iconApproved: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgreen',
  },
  iconRejected: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'pink',
  },
  issueBox: {
    alignItems: "center",
    backgroundColor: 'pink',
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    marginBottom: 10,
    height: 60,
  },
  issueText: {
    fontSize: 18,
    fontFamily: "Roboto",
    color: "#444",
  },
  readOnlyTxt: {
    fontSize: 22,
    borderRadius: 4,
    backgroundColor: 'pink',
    margin: 10,
    padding: 10,
    fontFamily: "Roboto",
  },
  viewContainerMax: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 600,
    paddingTop: 20,
    paddingLeft: 0,
    paddingRight: 0,
  },
  viewContainerMax1: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 350,
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 300,
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  labelFirst: {
    fontSize: 20,
    color: colors.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  labelFirst2: {
    fontSize: 20,
    color: colors.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    textAlign: 'center',
    width: '100%',
    marginTop: 30,
  },
  labelFirst3: {
    textAlign: 'center',
    width: '100%',
    marginBottom: 30,
    fontSize: 22,
    color: colors.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  iconStyle: {
    color: colors.primary,
    fontSize: 30,
    cursor: 'pointer'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
  },
  btnAdd: {
    width: 40
  },
  head: {
    backgroundColor: colors.orange,
    color: 'white'
  },
  text: {
    margin: 6
  },
});

const customStyles = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize: 30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: '#002f6c',
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: '#002f6c',
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: '#002f6c',
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: '#002f6c',
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: '#002f6c',
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: '#002f6c',
  cursor: 'pointer'
}