import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity  } from 'react-native';
import { connect } from 'react-redux';
import DropDown from '../widgets/dropdown';
import NewIssue from "../dialogs/newIssue";
export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      dialogVisible: false,
    };
  }

  // set initial value
  // componentWillMount(nextProps, nextState) {
  //   this.setState({value: this.props.initial});
  // }
  componentWillMount(nextProps, nextState) {
    this.setState({value: this.props.initial});
  }
  componentWillReceiveProps(nextProps){
    if(nextProps?.initial != this.props?.initial){
      this.setState({value: nextProps.initial})
    }
  }

  render() {
    const { options, label, onChange, id, isIssuable, onSubmitIssue, isCompleted, width } = this.props;
    const tempStyle = label === 'Operator name' || label === 'Verifier'
                      ? { paddingLeft: 10, paddingRight: 10 }
                      : null;
    return <>
    
    <NewIssue 
      visible={this.state.dialogVisible} 
      id={id} 
      label={label} 
      onSubmitIssue={onSubmitIssue} 
      handleClose={()=>this.setState({dialogVisible:false})} 
      />

    <View style={[styles.container, {
          paddingTop: width ? 0 : 5
        }, tempStyle]}>
      {
        label !== 'Operator name'
        ? <Text style={styles.label}>{label+" "}<Text style={{fontStyle: 'italic',color: '#002f6c',fontSize: 20,}}>{this.props.placeholder}</Text></Text>
        : null
      }
      {
        (isIssuable && isCompleted) && 
        <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
          this.setState({dialogVisible:true});
        }}>
          <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
        </TouchableOpacity>
      }

      <DropDown
        // initialOption={ typeof options.find(x => x.value == this.state.value) === 'undefined'  ? options[0] : {value:  this.state.value }}
        initialOption={ typeof options.find(x => x.value == this.state.value) === 'undefined'  ? options[0] : {value:  this.state.value }}
        updated={this.state.value}
        options={options}
        onSelect={(option) => onChange(id, option.id)}
      />
    </View>
    </>;
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 30,
    paddingTop: 5,
    paddingBottom: 10,
  },
  input: {
    fontSize: 22,
    color: '#424242',
    fontWeight: '400',
    alignSelf: 'stretch',
    height: 65,
    borderStyle: 'solid',
    borderColor: '#e7e7e7',
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 5,
    fontFamily: 'Roboto',

  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
});
